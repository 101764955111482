import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Table,
    Container,
    Modal,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Alert,
} from "reactstrap";
import { AuthContext } from "../../context";
// core components
import { URL } from '../../config'

const Tables = () => {
    const [languageData, setlanguageData] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [data, setData] = useState({
        name: "",
        short_name: ""
    })
    const [error, setError] = useState({ detail: undefined })


    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)

    const handleChange = (e) => {
        let updatedData = { ...data, [e.target.name]: e.target.value }
        setData(updatedData)
    };


    const toggleModal = () => {
        setModalOpen(!modalOpen)
        setError({ detail: undefined })
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const fetchlanguageData = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setlanguageData(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const deleteplace = (id) => {
        fetch(`${URL}/language/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchlanguageData()
            toggleDeleteModal()
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const submitForm = () => {
        if (String(data.name).length > 1 && String(data.short_name).length > 1) {
            if(String(data.short_name).length > 3){
                setError({detail: "Qısa ad maksimum 2 simvoldan ibarət ola bilər"})
                return
            }
            let url = toCreate ? `${URL}/language/` : `${URL}/language/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                setData([])
                setError({ detail: undefined })
                fetchlanguageData()
                toggleModal()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
        else {
            setError({ detail: "Bütün xanaları doldurmaq lazımdır" })
        }

    }
    const createModal = () => {
        setToCreate(true)
        toggleModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/language/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setData(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
        setItemID(id)
        setToCreate(false)
        toggleModal()

    }


    useEffect(() => {
        fetchlanguageData()
    }, [])



    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Dillər</h3>
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Dil əlavə et</Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }}>#id</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Dil</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Qısa adı</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {languageData && languageData.map((item, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{item['name']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['short_name']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button className="btn-icon btn-3" color="success" onClick={() => updateModal(item['id'])} type="button">Düzəliş etmək</Button>
                                                <Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(item['id'])} type="button">Silmək</Button>
                                            </td>
                                        </tr>
                                    )
                                    )}

                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
                {/* Modal */}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Dil əlavə etmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {error['detail'] &&
                            <Alert color="danger">
                                {error['detail']}
                            </Alert>
                        }
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">
                                            Dil
                                        </Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            onChange={(e) => handleChange(e)}
                                            value={data?.name}
                                            placeholder="Dil..."
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="short_name">
                                            Qısa adı
                                        </Label>
                                        <Input
                                            id="short_name"
                                            name="short_name"
                                            onChange={(e) => handleChange(e)}
                                            value={data?.short_name}
                                            placeholder="Qısa adı..."
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>



                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteModal}
                    toggle={() => toggleDeleteModal()}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Dili silmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Bu dili silmək istədiyinizə əminsiniz?
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => deleteplace(itemID)}>
                            Silmək
                        </Button>
                    </div>
                </Modal>


            </Container>
        </>
    );
};

export default Tables;
