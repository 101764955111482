import React, { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert
} from "reactstrap";
import { URL } from "../../config";

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [newPassword2, setNewPassword2] = useState()
    const [err, setErr] = useState()

    const token = JSON.parse(localStorage.getItem("token"))
    const history = useHistory()


    const submitForm = (e) => {
        e.preventDefault()
        if (oldPassword == undefined || oldPassword == ''){
            setErr({detail: 'Cari şifrəni daxil edin'})
            return
        }
        if (newPassword == undefined || newPassword == '' ||
            newPassword2 == undefined || newPassword2 == '' ||
            String(newPassword).length < 6 || String(newPassword2).length < 6
        ) {
            setErr({ detail: "Şifrə ən azı 6 simvoldan ibarət olmalıdır" })
            return
        }
        if (newPassword !== newPassword2) {
            setErr({ detail: 'Şifrələr uyğun gəlmir' })
            return
        }

        fetch(URL + "/auth/change-password", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        })
            .then(result => {
                setErr()
                setOldPassword()
                setNewPassword()
                setNewPassword2()
                history.push("/admin/menu")
            }).catch(error => {
                error.json().then((body) => {
                    setErr(body)
                });
            })
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col lg="5">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-3">
                                Şifrəni dəyişmək
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            {err && (
                                <Alert color="danger">
                                    {err['detail']}
                                </Alert>
                            )}
                            <Form role="form" onSubmit={submitForm} id="form1">
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Cari şifrə"
                                            type="password"
                                            autoComplete="new-email"
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Yeni şifrə"
                                            type="password"
                                            autoComplete="new-password"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Yeni şifrənin təkrarı"
                                            type="password"
                                            autoComplete="new-password"
                                            onChange={(e) => setNewPassword2(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id=" customCheckLogin"
                                        type="checkbox"
                                    />
                                </div>
                                <div className="text-center">
                                    <Button className="my-4" color="danger" onClick={() => history.push("/admin/menu")}>
                                        İmtina etmək
                                    </Button>
                                    <Button className="my-4" color="primary" type="submit" form="form1">
                                        Şifrəni dəyiş
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ChangePassword;
