import React, { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import { AuthContext } from "../../context";
import { URL } from "../../config";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [err, setErr] = useState()

  const authContext = useContext(AuthContext)
  const history = useHistory();



  const submitForm = (e) => {
    e.preventDefault()
    if(!email | email?.length < 4){
        setErr({detail:"Emaili daxil edin!"})
        return
    }
    if(!password){
        setErr({detail:"Sifreni daxil edin!"})
        return
    }
    if(email && password){
      let url = `${URL}/auth/access-token`
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, password: password })
      }).then(result => {
        if (!result.ok) throw result;
        return result.json();
      })
        .then(result => {
          authContext.login(result)
          setErr()
          setEmail()
          setPassword()
          history.push("/admin/menu")
        }).catch(error => {
          error.json().then((body) => {
            setErr(body)
          });
        })
    }
  }

  return (
    <>
      <Row className="justify-content-center">
        <Col lg="5">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                Giriş etmək
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              {err && (
                <Alert color="danger">
                  {err['detail']}
                </Alert>
              )}
              <Form role="form" onSubmit={submitForm} id="form1">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Şifrə"
                      type="password"
                      autoComplete="new-password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit" form="form1">
                    Giriş
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
