import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Table,
    Container,
    Modal,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Input,
    Alert,
} from "reactstrap";
import { URL } from "../../config";
import Select from 'react-select'
import { AuthContext } from "../../context";

const Tables = () => {
    const [translationData, settranslationData] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [key, setkey] = useState()
    const [languageSelect, setLanguageSelect] = useState({ label: 'az', value: 'az' })
    const [languageOptions, setLanguageOptions] = useState([])
    const [data, setData] = useState({
        key: "",
        value: ""
    })
    const [error, setError] = useState({ detail: undefined })

    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)

    const handleDataChange = (e) => {
        let updatedData = { ...data, [e.target.name]: e.target.value }
        setData(updatedData)
    }

    const handleChange = (e, key) => {
        let updatedData = { ...translationData }
        updatedData[key] = e.target.value
        settranslationData(updatedData)
    };

    const handleLanguageChange = (selected) => {
        setLanguageSelect(selected)
    }


    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            let labeledLanguages = result.map(language => {
                return { value: language['short_name'], label: language['short_name'] }
            })
            setLanguageOptions(labeledLanguages)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }


    const saveCurrentRecord = (key) => {
        let lang_short_name = languageSelect.value
        fetch(`${URL}/language/translations/update/${lang_short_name}`, {
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ key: key, value: translationData[key] })
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setData({})
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen)
        setError({detail: undefined})
    }

    const toggleDeleteModal = (key) => {
        setkey(key)
        setDeleteModal(!deleteModal)
    }

    const fetchtranslationData = () => {
        let lang_short_name = languageSelect.value
        fetch(`${URL}/language/translations/${lang_short_name}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            settranslationData(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const deleteTranslation = () => {
        fetch(`${URL}/language/translations/delete`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ key: key })
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            toggleDeleteModal()
            fetchtranslationData()
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })

    }

    const submitForm = () => {
        if (String(data.key).length > 1 && String(data.value).length > 1) {
            let url = `${URL}/language/translations`
            let method = 'POST'
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ key: data.key, value: data.value })
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                setError({detail: undefined})
                setData()
                toggleModal()
                fetchtranslationData()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
        else {
            setError({ detail: "Bütün xanaları doldurmaq lazımdır" })
        }
    }

    const createModal = () => {
        toggleModal()
    }


    useEffect(() => {
        fetchtranslationData()
        getLanguages()
    }, [languageSelect])


    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Tərcümələr</h3>
                                <FormGroup>
                                    <Select
                                        value={languageSelect}
                                        onChange={handleLanguageChange}
                                        isSearchable={false}
                                        options={languageOptions}
                                        placeholder="Dil..."
                                    />
                                </FormGroup>
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Tərcümə əlavə et</Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }}>#id</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Adı</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Tərcümə</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {translationData && Object.keys(translationData).map((key, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{key}</td>
                                            <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <FormGroup>
                                                    <Input
                                                        id="value"
                                                        name="value"
                                                        onChange={(e) => handleChange(e, key)}
                                                        value={translationData[key]}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button className="btn-icon btn-3" color="success" onClick={() => saveCurrentRecord(key)} type="button">Yadda saxlamaq</Button>
                                                <Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(key)} type="button">Silmək</Button>
                                            </td>
                                        </tr>
                                    )
                                    )}

                                </tbody>
                            </Table>
                            <CardFooter className="py-4">

                            </CardFooter>
                        </Card>
                    </div>
                </Row>
                {/* Modal */}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Tərcümə əlavə etmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {error['detail'] &&
                            <Alert color="danger">
                                {error['detail']}
                            </Alert>
                        }
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="key">
                                            Adı
                                        </Label>
                                        <Input
                                            id="key"
                                            name="key"
                                            onChange={(e) => handleDataChange(e)}
                                            value={data?.key}
                                            placeholder="Adı..."
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="value">
                                            Tərcüməsi
                                        </Label>
                                        <Input
                                            id="value"
                                            name="value"
                                            onChange={(e) => handleDataChange(e)}
                                            value={data?.value}
                                            placeholder="Tərcüməsi..."
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            Əlavə etmək
                        </Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteModal}
                    toggle={() => toggleDeleteModal()}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Dili silmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Bu tərcüməni silmək istədiyinizə əminsiniz?
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => deleteTranslation()}>
                            Silmək
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
};

export default Tables;
