import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const GeneralPagination = ({ data, changePage }) => {
    return (
        <>
            {data &&
                <nav aria-label="...">
                    <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                    >
                        <PaginationItem disabled={data['current_page']===1}>
                            <PaginationLink
                                onClick={() => changePage(1)}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={data['current_page']===1}>
                            <PaginationLink
                                onClick={() => changePage(data['current_page'] - 1)}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem className="active">
                            <PaginationLink>
                                {data['current_page']}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={data['current_page']===data['num_pages']}>
                            <PaginationLink
                                onClick={(e) => changePage(data['current_page'] + 1)}
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={data['current_page']===data['num_pages']}>
                            <PaginationLink
                                onClick={(e) => changePage(data['num_pages'])}
                            >
                                <i className="fas fa-angle-right" />
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            }
        </>
    )
}

export default GeneralPagination