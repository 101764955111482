import Qazi from "views/examples/Qazi";
import Sehid from "views/examples/Sehid";
import SoldierType from "views/examples/SoldierType"
import EducationType from "views/examples/EducationType"
import FamilyType from "views/examples/FamilyType"
import TroopType from "views/examples/TroopType"
import DutyType from "views/examples/DutyType"
import Menu from "views/examples/Menu";
import Medal from "views/examples/Medal";
import Rank from "views/examples/Rank"
import Place from "views/examples/Place"
import UsefulLink from "views/examples/UsefulLink"
import Chronology from "views/examples/Chronology"
import Language from "views/examples/Language"
import Translation from "views/examples/Translation"
import Contact from "views/examples/Contact"
import User from "views/examples/User"
import ContactUsInfo from "views/examples/ContactUsInfo"
import AboutUs from "views/examples/AboutUs"


var routes = [
  {
    path: "/menu",
    name: "Menular",
    icon: "ni ni-bullet-list-67 text-red",
    component: Menu,
    layout: "/admin",
  },
  {
    path: "/chronologies",
    name: "Xronologiya",
    icon: "ni ni-calendar-grid-58 text-purple",
    component: Chronology,
    layout: "/admin",
  },
  {
    path: "/rank",
    name: "Rütbələr",
    icon: "ni ni-chart-bar-32 text-black ",
    component: Rank,
    layout: "/admin",
  },
  {
    path: "/place",
    name: "Şəhərlər",
    icon: "ni ni-square-pin text-yellow",
    component: Place,
    layout: "/admin",
  },
  {
    path: "/medal",
    name: "Medallar",
    icon: "ni ni-trophy text-blue",
    component: Medal,
    layout: "/admin",
  },
  {
    path: "/soldier-types",
    name: "Əsgər tipləri",
    icon: "ni ni-ui-04 text-red",
    component: SoldierType,
    layout: "/admin",
  },
  {
    path: "/eduation-types",
    name: "Təhsil tipləri",
    icon: "ni ni-hat-3 text-red",
    component: EducationType,
    layout: "/admin",
  },
  {
    path: "/family-types",
    name: "Ailə vəziyyəti tipləri",
    icon: "ni ni-ui-04 text-blue",
    component: FamilyType,
    layout: "/admin",
  },
  {
    path: "/troop-types",
    name: "Qoşun növləri",
    icon: "ni ni-ui-04 text-red",
    component: TroopType,
    layout: "/admin",
  },
  {
    path: "/duty-types",
    name: "Mükəlləfiyyət növləri",
    icon: "ni ni-tag text-red",
    component: DutyType,
    layout: "/admin",
  },
  {
    path: "/veterans",
    name: "Qazilər",
    icon: "ni ni-circle-08 text-green",
    component: Qazi,
    layout: "/admin",
  },
  {
    path: "/martyrs",
    name: "Şəhidlər",
    icon: "ni ni-circle-08  text-red",
    component: Sehid,
    layout: "/admin",
  },
  {
    path: "/useful-links",
    name: "Faydalı keçidlər",
    icon: "ni ni-active-40  text-black",
    component: UsefulLink,
    layout: "/admin",
  },
  {
    path: "/about-us",
    name: "Haqqımızda",
    icon: "ni ni-archive-2 text-black ",
    component: AboutUs,
    layout: "/admin",
  },
  {
    path: "/contact-us-info",
    name: "Əlaqə məlumatları",
    icon: "ni ni-square-pin text-purple",
    component: ContactUsInfo,
    layout: "/admin",
  },
  {
    path: "/language",
    name: "Dillər",
    icon: "ni ni-book-bookmark text-blue",
    component: Language,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Bizimlə əlaqə",
    icon: "ni ni-email-83 text-green",
    component: Contact,
    layout: "/admin",
  },
  {
    path: "/static-translation",
    name: "Tərcümələr",
    icon: "ni ni-world-2 text-red",
    component: Translation,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "İstifadəçilər",
    icon: "ni ni-single-02 text-purple",
    component: User,
    layout: "/admin",
  },
];
export default routes;
