import { useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    Button,
    Table,
    Container,
    Modal,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Alert
} from "reactstrap";
import { URL } from "../../config";
// core components
import Select from "react-select"

const Tables = () => {
    const [userData, setuserData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [data, setData] = useState({
        full_name: null,
        email: null,
        password: null,
        role: "admin"
    })
    const [error, setError] = useState({ detail: undefined })

    const roleOptions = [
        { value: "user", label: "User" },
        { value: "admin", label: "Admin" }
    ]

    const handleChange = (e, selectObj = null) => {
        let updatedData;
        // form field is react select
        if (selectObj) {
            updatedData = { ...data, [selectObj.name]: e }
        }
        // other form fields
        else {
            updatedData = { ...data, [e.target.name]: e.target.value }
        }
        setData(updatedData)
    };


    const toggleModal = () => {
        setModalOpen(!modalOpen)
        setError({ detail: undefined })
        setData({
            full_name: null,
            email: null,
            password: null,
            role: "admin"
        })
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const token = JSON.parse(localStorage.getItem("token"))

    const fetchuserData = () => {
        fetch(`${URL}/user/all`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => res.json()).then(result => {
            setuserData(result)
        }).catch(err => { })
    }

    const deleteplace = (id) => {
        fetch(`${URL}/user/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => res.json()).then(msg => {
            const remainingResult = userData.filter(result => result.id !== id)
            setuserData(remainingResult)
            toggleDeleteModal()
        })
    }

    const submitForm = () => {
        let condition = toCreate ? data?.email && data?.full_name && data?.password && data?.role : data?.full_name && data?.role
        if(data?.password && data?.password.length < 6){
            setError({ detail: 'Şifrə ən azı 6 simvoldan ibarət olmalıdır' })
        }
        else if (condition) {
            let requestData = { ...data }
            requestData['role'] = requestData['role'].value
            let url = toCreate ? `${URL}/user/create` : `${URL}/user/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            }).then(res => {
                setData([])
                setError({ detail: undefined })
                fetchuserData()
                toggleModal()
            }).catch(err => { })
        }
        else {
            setError({ detail: 'Bütün xanaları doldurmaq lazımdır!' })
        }
    }


    const createModal = () => {
        setToCreate(true)
        toggleModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/user/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => res.json()).then(result => {
            let data = { ...result }
            data['role'] = { label: data['role'], value: data['role'] }
            setData(data)
        })
        setItemID(id)
        setToCreate(false)
        toggleModal()
    }


    useEffect(() => {
        fetchuserData()
    }, [])



    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">İstifadəçilər</h3>
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">İstifadəçi əlavə et</Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }}>#id</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Tam adı</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Email</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Role</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData.length > 0 && userData.map((item, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{item['full_name']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['email']}</td>
                                            <td style={{ textAlign: 'center' }}>{item['role']}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button className="btn-icon btn-3" color="success" onClick={() => updateModal(item['id'])} type="button">Düzəliş etmək</Button>
                                                <Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(item['id'])} type="button">Silmək</Button>
                                            </td>
                                        </tr>
                                    )
                                    )}

                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            İstifadəçi əlavə etmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {error['detail'] && (
                            <Alert color="danger">
                                {error['detail']}
                            </Alert>
                        )}
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="full_name">
                                            Tam ad
                                        </Label>
                                        <Input
                                            id="full_name"
                                            name="full_name"
                                            onChange={(e) => handleChange(e)}
                                            value={data?.full_name}
                                            placeholder="Adı..."
                                            type="text"
                                        />
                                    </FormGroup>
                                    {toCreate &&
                                        <FormGroup>
                                            <Label for="email">
                                                Email
                                            </Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                onChange={(e) => handleChange(e)}
                                                value={data?.email}
                                                placeholder="Email..."
                                                type="email"
                                            />
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label for="role">
                                            Rol
                                        </Label>
                                        <Select
                                            name="role"
                                            value={data.role}
                                            options={roleOptions}
                                            isSearchable={false}
                                            placeholder="Rol..."
                                            onChange={(e) => handleChange(e, { name: "role" })}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">
                                            Şifrə
                                        </Label>
                                        <Input
                                            id="password"
                                            name="password"
                                            onChange={(e) => handleChange(e)}
                                            value={data?.password}
                                            placeholder="Şifrə..."
                                            type="password"
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>
                        </Form>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>


                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteModal}
                    toggle={() => toggleDeleteModal()}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            İstifadəçini silmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Bu istifadəçini silmək istədiyinizə əminsiniz?
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => deleteplace(itemID)}>
                            Silmək
                        </Button>
                    </div>
                </Modal>


            </Container>
        </>
    );
};

export default Tables;
