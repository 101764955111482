import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Container,
    Row,
    FormGroup,
    Input,
    Col,
    Form,
    TabPane,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Modal,
    CustomInput,
    FormFeedback
} from "reactstrap";
import classnames from 'classnames'

import { MEDIA_URL, URL, EXTENSIONS } from '../../config'
// core components
import GeneralTable from '../components/GeneralTable'
import DeleteModal from '../components/DeleteModal'
import { AuthContext } from "../../context";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build'

const AboutUs = () => {
    const [allRecords, setallRecords] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [oneRecord, setoneRecord] = useState([])
    const [file, setFile] = useState(undefined)
    const [aboutUsImage, setAboutUsImage] = useState()
    const [query, setQuery] = useState()
    const [sort, setSort] = useState({ field: "", order: "" })
    const [languages, setLanguages] = useState([])
    const [formErrors, setFormErrors] = useState({ about_us: {}, about_us_lang: [] })
    const [contents, setContents] = useState([])

    const authContext = useContext(AuthContext)
    const token = JSON.parse(localStorage.getItem("token"))

    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setLanguages(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const handleImagePreview = (e) => {
        e.preventDefault()
        let image_as_file = e.target.files[0];
        setFile(image_as_file)
    }

    const handleQuery = (e) => {
        let value = e.target.value
        if (value.length >= 3) {
            fetchallRecords(1, null, value)
        }
        if (value.length < query?.length) {
            fetchallRecords(1)
        }
        setQuery(value)
    }

    const handleSort = (field) => {
        let order;
        if (sort.order === "asc" && sort.field === field)
            order = "desc";
        else {
            order = "asc"
        }
        let orderObj = { field: field, order: order }
        setSort(orderObj)
        fetchallRecords(1, orderObj, query)
    }

    const getIcon = (field) => {
        if (field === sort.field) {
            switch (sort.order) {
                case "asc":
                    return (<i className="fas fa-angle-up" />)
                case "desc":
                    return (<i className="fas fa-angle-down" />)
            }
        }
    }

    const handleChange = (e, index, lang_id) => {
        e.preventDefault()
        let updatedData

        if (oneRecord[index]) {
            updatedData = [...oneRecord.slice(0, index), { ...oneRecord[index], [e.target.name]: e.target.value }, ...oneRecord.slice(index + 1)]
        }
        else {
            if (e.target.value)
                updatedData = [...oneRecord, { [e.target.name]: e.target.value, lang_id: lang_id }]
        }
        setoneRecord(updatedData)
    };

    const toggle = (index) => {
        setActiveTab(index)
    };

    const toggleCreateUpdateModal = () => {
        if (!modalOpen) {
            getLanguages()
        }
        setModalOpen(!modalOpen)
        setFile("")
        setAboutUsImage()
        setoneRecord([])
        setActiveTab(1)
        setFormErrors({ about_us: {}, about_us_lang: [] })
    }
    const changePage = (page) => {
        fetchallRecords(page, sort)
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }


    const fetchallRecords = (page = 1, orderObj, query) => {
        let url = `${URL}/about_us/last/az`
        if (orderObj?.field) {
            url += `&field=${orderObj.field}&order=${orderObj.order}`
        }
        if (query) {
            url += `&q=${query}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setallRecords({'items': [result,]})
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }


    const deleteabout_us = (id) => {
        fetch(`${URL}/about_us/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchallRecords(allRecords['current_page'], sort)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const getExtensionFromFilename = (filename) => {
        let splittedFilename = filename.split(".")
        // if (splittedFilename.length > 2) {
        //     return splittedFilename.slice(-2).join('.')
        // }
        return splittedFilename.slice(-1).join('.')
    }

    const validateHaqqımızda = () => {
        let obj_lang = oneRecord
        let errors = { about_us: {}, about_us_lang: [] }
        let valid = true
        errors.about_us_lang[0] = {}
        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.title === undefined || obj_lang[0]?.title == '') {
            errors.about_us_lang[0] = { ...errors.about_us_lang[0], title: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.description === undefined || obj_lang[0]?.description == '') {
            errors.about_us_lang[0] = { ...errors.about_us_lang[0], description: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!file) {
            errors.about_us = { file: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }
        else {
            if (!EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
                errors.about_us = { file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
                valid = false
            }
        }
        setFormErrors(errors)
        return valid
    }

    const validateHaqqımızdaUpdate = () => {
        let obj_lang = oneRecord
        let errors = { about_us: {}, about_us_lang: [] }
        let valid = true

        for (let i = 0; i < obj_lang.length; i++) {
            errors.about_us_lang[i] = {}
            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.title === undefined || obj_lang[i]?.title == '') {
                errors.about_us_lang[i] = { ...errors.about_us_lang[i], title: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }

            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.content === undefined || obj_lang[i]?.content == '') {
                errors.about_us_lang[i] = { ...errors.about_us_lang[i], content: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }
        }

        if (file) {
            if (!EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
                errors.about_us = { file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
                valid = false
            }
        }
        setFormErrors(errors)
        return valid
    }


    const submitForm = (e) => {
        let validate = toCreate ? validateHaqqımızda : validateHaqqımızdaUpdate
        if (validate()) {
            let formData = new FormData()
            if (file)
                formData.append('file', file)
            formData.append('obj_in', `{
                                "about_us": {
                                    "image": ""
                                },
                                "about_us_lang":${JSON.stringify(oneRecord)}
                                }`
            )
            let url = toCreate ? `${URL}/about_us/` : `${URL}/about_us/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                fetchallRecords(allRecords['page'], sort)
                toggleCreateUpdateModal()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
    }

    const createModal = () => {
        setToCreate(true)
        toggleCreateUpdateModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/about_us/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            let contentData = result['about_us_lang'].map(item => item['content'])
            setContents(contentData)
            setAboutUsImage(result['image'])
            setoneRecord(result['about_us_lang'])
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
        getLanguages()
        setItemID(id)
        setToCreate(false)
        toggleCreateUpdateModal()
    }

    const handleContent = (event, editor, index) => {
        const data = editor.getData()
        let obj = [...oneRecord]
        let contentData = [...contents]
        obj[index].content = data
        contentData[index] = data
        setoneRecord(obj)
        setContents(contentData)
    }


    useEffect(() => {
        fetchallRecords()
    }, [])




    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Haqqımızda</h3>
                            </CardHeader>
                            <GeneralTable
                                columns={[
                                    { title: 'ID', field: 'id' },
                                    { title: 'Başlıq', field: 'title', count: 40 },
                                    { title: 'Şəkil', field: 'image', notSortable: true }
                                ]}
                                handleSort={handleSort}
                                getIcon={getIcon}
                                toggleUpdateModal={updateModal}
                                toggleDeleteModal={toggleDeleteModal}
                                condition_field={'id'}
                                data={allRecords}
                            />
                        </Card>
                    </div>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleCreateUpdateModal()}
                    size={'xl'}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Düzəliş etmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col xs="6" sm="4" md="2">
                                <Nav tabs vertical pills>
                                    {languages && languages.map((item, index) => (
                                        <div style={{ marginTop: "10px" }}>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === index + 1 })}
                                                    onClick={() => {
                                                        toggle(index + 1);
                                                    }}
                                                >
                                                    {String(item['short_name']).toUpperCase()}
                                                    {Object.keys(formErrors?.about_us_lang[index] || {}).length > 0 &&
                                                        <i
                                                            style={{ color: 'red' }}
                                                            className="ni ni-fat-remove"
                                                        />
                                                    }
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    ))}
                                </Nav>
                            </Col>

                            <Col xs="6" sm="6" md="8">
                                <TabContent activeTab={activeTab}>
                                    {languages && languages.map((language_data, index) => (
                                        <TabPane tabId={index + 1}>
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <Form onSubmit={(e) => submitForm(e)}>
                                                            <FormGroup>
                                                                <Label for="title">
                                                                    Başlıq
                                                                </Label>
                                                                <Input
                                                                    id="title"
                                                                    onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                    name="title"
                                                                    value={oneRecord[index] && oneRecord[index].title}
                                                                    placeholder="Başlıq"
                                                                    type="text"
                                                                    invalid={formErrors?.about_us_lang[index]?.title?.length > 0}
                                                                />
                                                                <FormFeedback>
                                                                    {formErrors?.about_us_lang[index]?.title}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="content">
                                                                    Kontent
                                                                </Label>
                                                                <CKEditor
                                                                    id="editor-chronology"
                                                                    editor={ClassicEditor}
                                                                    data={contents?.[index] || " "}
                                                                    onChange={(event, editor) => handleContent(event, editor, index)}
                                                                    config={{
                                                                        ckfinder: {
                                                                            uploadUrl: URL + '/about_us/editor-image-upload',
                                                                        }
                                                                    }}
                                                                    onInit={editor => {

                                                                    }}
                                                                />
                                                                <Input type="hidden" invalid={true} />
                                                                <FormFeedback>
                                                                    {formErrors?.about_us_lang[index]?.content}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>
                                    ))}
                                    <FormGroup>
                                        <Label for="image">
                                            Şəkil
                                        </Label>
                                        {aboutUsImage && <img style={{ display: 'block', marginBottom: 10 }} width={120} height={120} src={`${MEDIA_URL}${aboutUsImage}?${new Date().getTime()}`} />}

                                        <CustomInput
                                            id="image"
                                            multiple
                                            label="Şəkil seçmək"
                                            onChange={(e) => handleImagePreview(e)}
                                            name="image"
                                            type="file"
                                            invalid={formErrors?.about_us?.file?.length > 0}
                                        >
                                            <FormFeedback>
                                                {formErrors?.about_us?.file}
                                            </FormFeedback>
                                        </CustomInput>
                                    </FormGroup>
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>

                <DeleteModal
                    isOpen={deleteModal}
                    toggleDeleteModal={toggleDeleteModal}
                    title={"Haqqımızdaı silmək"}
                    bodyText={"Bu about_usı silmək istədiyinizə əminsiniz?"}
                    itemID={itemID}
                    deleteAction={deleteabout_us} />
            </Container>
        </>
    );
};

export default AboutUs;
