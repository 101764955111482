import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Container,
    Row,
    FormGroup,
    Input,
    Label,
    Col,
    Form,
    Modal,
    TabContent,
    Nav,
    NavLink,
    NavItem,
    TabPane,
    CustomInput,
    FormFeedback
} from "reactstrap";
import Select from "react-select";
import classnames from 'classnames'
import { URL, MEDIA_URL, EXTENSIONS } from '../../config'
import GeneralTable from '../components/GeneralTable'
import DeleteModal from '../components/DeleteModal'
import GeneralPagination from "../components/GeneralPagination";
import { AuthContext } from "../../context";
import "rc-time-picker/assets/index.css";
// import { CKEditor } from 'ckeditor4-react';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build'


const Chronology = () => {
    const [allRecords, setallRecords] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [oneRecord, setoneRecord] = useState([])
    const [link, setLink] = useState()
    const [time, setTime] = useState()
    const [isMain, setIsMain] = useState(false)
    const [file, setFile] = useState()
    const [datesOptions, setDatesOptions] = useState([])
    const [dateSelect, setDateSelect] = useState({ value: 1, label: "2020-09-27" })
    const [dateSelect2, setDateSelect2] = useState()
    const [placeOptions, setPlaceOptions] = useState()
    const [placeOptionsDict, setPlaceOptionsDict] = useState()
    const [placeSelect, setPlaceSelect] = useState()
    const [query, setQuery] = useState()
    const [sort, setSort] = useState({ field: "", order: "" })
    const [languages, setLanguages] = useState([])
    const [chronologyImage, setChronologyImage] = useState()
    const [formErrors, setFormErrors] = useState({ article: {}, article_lang: [] })
    const [contents, setContents] = useState([])

    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)

    const handleChange = (e, index, lang_id) => {
        let updatedData
        if (oneRecord[index]) {
            updatedData = [...oneRecord.slice(0, index), { ...oneRecord[index], [e.target.name]: e.target.value }, ...oneRecord.slice(index + 1)]
        }
        else {
            if (e.target.value)
                updatedData = [...oneRecord, { [e.target.name]: e.target.value, lang_id: lang_id }]
        }
        setoneRecord(updatedData)
    };

    const handleImagePreview = (e) => {
        e.preventDefault()
        let image_as_file = e.target.files[0];
        setFile(image_as_file)
    }

    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setLanguages(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const handleQuery = (e) => {
        let value = e.target.value
        if (value.length >= 3) {
            fetchallRecords(1, null, value, null)
        }
        if (value.length < query?.length) {
            fetchallRecords(1, null, null, dateSelect.value)
        }
        setQuery(value)
    }

    const toggle = (index) => {
        setActiveTab(index)
    };

    const toggleCreateUpdateModal = () => {
        getLanguages()
        getPlaceOptions()
        setActiveTab(1)
        if (modalOpen) {
            setTime()
            setLink()
            setFile()
            setDateSelect2()
            setPlaceSelect()
            setIsMain(false)
            setoneRecord([])
            setChronologyImage()
            setFormErrors({ article: {}, article_lang: [] })
        }
        setModalOpen(!modalOpen)
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const getDatesOptions = async (url) => {
        try {
            const response = await fetch(`${URL}/date/all`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            if (!response.ok) throw response
            const all_data = await response.json()
            const result = all_data.map(item => {
                return { value: item['id'], label: item['event_date'] }
            })
            setDatesOptions(result)
        } catch (error) {
            if (error.status == 401) {
                authContext.logout()
            }
        }
    }

    const getPlaceOptions = async () => {
        try {
            const response = await fetch(`${URL}/placeaz/all`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            if (!response.ok) throw response
            const all_places = await response.json()
            let all_data_as_dict = {}
            for (let i = 0; i < all_places.length; i++) {
                all_data_as_dict[all_places[i]['id']] = { value: all_places[i]['id'], label: all_places[i]['title'] }
            }
            setPlaceOptionsDict(all_data_as_dict)
            const result = all_places.map(item => ({ value: item['id'], label: item['title'] }))
            setPlaceOptions(result)
        } catch (error) {
            if (error.status == 401) {
                authContext.logout()
            }
        }
    }


    const fetchallRecords = (page = 1, orderObj = null, query = null, date_id = null) => {
        let url = `${URL}/chronology/az/all?page=${page}`
        if (orderObj?.field) {
            url += `&field=${orderObj.field}&order=${orderObj.order}`
        }
        if (query) {
            url += `&q=${query}`
        }
        if (date_id) {
            url += `&date_id=${date_id}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setallRecords(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const handleDateChange = (e) => {
        setDateSelect(e)
        let date_id = e.value
        fetchallRecords(allRecords['page'], sort, query, date_id)
    }

    const getIcon = (field) => {
        if (field === sort.field) {
            switch (sort.order) {
                case "asc":
                    return (<i className="fas fa-angle-up" />)
                case "desc":
                    return (<i className="fas fa-angle-down" />)
            }
        }
    }

    const changePage = (page) => {
        fetchallRecords(page, sort, null, dateSelect.value)
    }

    const handleSort = (field) => {
        let order;
        if (sort.order === "asc" && sort.field === field)
            order = "desc";
        else {
            order = "asc"
        }
        let orderObj = { field: field, order: order }
        setSort(orderObj)
        fetchallRecords(1, orderObj, query)
    }

    const deleteArticle = (id) => {
        fetch(`${URL}/chronology/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchallRecords(allRecords['page'], sort, query, dateSelect.value)
            toggleDeleteModal()
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const getExtensionFromFilename = (filename) => {
        let splittedFilename = filename.split(".")
        return splittedFilename.slice(-1).join('.')
    }

    const validateArticle = () => {
        let obj_lang = oneRecord
        let errors = { article: {}, article_lang: [] }
        let valid = true
        errors.article_lang[0] = {}
        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.title === undefined || obj_lang[0]?.title == '') {
            errors.article_lang[0] = { ...errors.article_lang[0], title: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.description === undefined || obj_lang[0]?.description == '') {
            errors.article_lang[0] = { ...errors.article_lang[0], description: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!isMain && !obj_lang || !obj_lang[0] || obj_lang[0]?.content === undefined || obj_lang[0]?.content == '') {
            errors.article_lang[0] = { ...errors.article_lang[0], content: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!file) {
            errors.article = { ...errors.article, file: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }
        else {
            if (!EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
                errors.article = { ...errors.article, file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
                valid = false
            }
        }
        // if (!time) {
        //     errors.article = { ...errors.article, time: 'Bu xananı doldurmaq lazımdır!' }
        //     valid = false
        // }

        if (!placeSelect) {
            errors.article = { ...errors.article, place: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!isMain && !dateSelect2) {
            errors.article = { ...errors.article, date: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        setFormErrors(errors)
        return valid
    }

    const validateArticleUpdate = () => {
        let obj_lang = oneRecord
        let errors = { article: {}, article_lang: [] }
        let valid = true
        for (let i = 0; i < obj_lang.length; i++) {

            errors.article_lang[i] = {}
            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.title === undefined || obj_lang[i]?.title == '') {
                errors.article_lang[i] = { ...errors.article_lang[i], title: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }

            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.description === undefined || obj_lang[i]?.description == '') {
                errors.article_lang[i] = { ...errors.article_lang[i], description: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }

            if (!isMain && !obj_lang || !obj_lang[i] || obj_lang[i]?.content === undefined || obj_lang[i]?.content == '') {
                errors.article_lang[i] = { ...errors.article_lang[i], content: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }
        }

        if (file && !EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
            errors.article = { ...errors.article, file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
            valid = false
        }
        // if (!time) {
        //     errors.article = { ...errors.article, time: 'Bu xananı doldurmaq lazımdır!' }
        //     valid = false
        // }

        if (!placeSelect) {
            errors.article = { ...errors.article, place: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!isMain && !dateSelect2) {
            errors.article = { ...errors.article, date: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }
        setFormErrors(errors)
        return valid
    }

    const submitForm = () => {
        let validate = toCreate ? validateArticle : validateArticleUpdate
        if (validate()) {
            let url = toCreate ? `${URL}/chronology/` : `${URL}/chronology/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            let formData = new FormData()
            let place_ids = placeSelect.map(item => item['value'])
            if (file)
                formData.append('file', file)
            // event_time: time,
            const requestData = {
                article: {
                    link: link,
                    image: "",
                    date_id: dateSelect2.value,
                    place_ids: place_ids
                },
                article_lang: oneRecord
            }
            formData.append('obj_in', JSON.stringify(requestData))
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                fetchallRecords(allRecords['current_page'], sort, null, dateSelect.value)
                toggleCreateUpdateModal()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
    }

    const createModal = () => {
        setToCreate(true)
        toggleCreateUpdateModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/chronology/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setoneRecord(result['article_lang'])
            let contentData = result['article_lang'].map(item => item['content'])
            setContents(contentData)
            setLink(result['link'])
            setDateSelect2({ value: result['date']?.id, label: result['date']?.event_date })
            setIsMain(result['is_main'])
            setChronologyImage(result['image'])
            setPlaceSelect(result['places'].map(item => placeOptionsDict[item['id']]))
            setItemID(id)
            setToCreate(false)
            toggleCreateUpdateModal()
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const handleContent = (event, editor, index) => {
        const data = editor.getData()
        console.log(data)
        let obj = [...oneRecord]
        let contentData = [...contents]
        obj[index].content = data
        contentData[index] = data
        setoneRecord(obj)
        setContents(contentData)
    }

    useEffect(() => {
        getPlaceOptions()
        getDatesOptions()
        fetchallRecords(1, null, null, 1)
    }, [])

    let TimerPickerCss = `
        .rc-time-picker{
            display: block;
        }
        .rc-time-picker-input{
            padding: 10px;
            font-size: 16px;
        }
    `
    if (formErrors?.article?.time?.length > 0) {
        TimerPickerCss += `
        .rc-time-picker-input{
            padding: 10px;
            font-size: 16px;
            border: 1px solid red;
        }`
    }

    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex">
                                <h3 className="mb-0">44 gün</h3>
                                <FormGroup style={{ marginLeft: '35%' }}>
                                    <Label for="dates">
                                        Günlər
                                    </Label>
                                    <Select
                                        onChange={handleDateChange}
                                        options={datesOptions}
                                        value={dateSelect}
                                        placeholder="Günlər"
                                    />
                                </FormGroup>
                            </CardHeader>
                            <GeneralTable
                                columns={[
                                    { title: 'ID', field: 'id' },
                                    { title: 'Başlıq', field: 'title', count: 40 },
                                    { title: 'Qısa Başlıq', field: 'description', count: 40 }
                                ]}
                                condition_field={'is_main'}
                                handleSort={handleSort}
                                getIcon={getIcon}
                                toggleUpdateModal={updateModal}
                                toggleDeleteModal={toggleDeleteModal}
                                data={allRecords}
                            />
                            <CardFooter className="py-4">
                                <GeneralPagination
                                    data={allRecords}
                                    changePage={changePage}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
                <Modal
                    className="modal-dialog-centered"
                    size="xl"
                    isOpen={modalOpen}
                    toggle={() => toggleCreateUpdateModal()}
                    backdrop={'static'}
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Hadisə əlavə etmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col xs="6" sm="4" md="2">
                                <Nav tabs vertical pills>
                                    {languages && languages.map((item, index) => (
                                        <div style={{ marginTop: "10px" }}>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === index + 1 })}
                                                    onClick={() => {
                                                        toggle(index + 1);
                                                    }}
                                                >
                                                    {String(item['short_name']).toUpperCase()}
                                                    {Object.keys(formErrors?.article_lang[index] || {}).length > 0 &&
                                                        <i
                                                            style={{ color: 'red' }}
                                                            className="ni ni-fat-remove"
                                                        />
                                                    }
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    ))}
                                </Nav>
                            </Col>

                            <Col xs="6" sm="6" md="8">
                                <TabContent activeTab={activeTab}>
                                    {languages && languages.map((language_data, index) => (
                                        <TabPane tabId={index + 1}>
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <Form onSubmit={(e) => submitForm(e)}>
                                                            <FormGroup>
                                                                <Label for="title">
                                                                    Başlıq
                                                                </Label>
                                                                <Input
                                                                    id="title"
                                                                    onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                    name="title"
                                                                    value={oneRecord[index] && oneRecord[index].title}
                                                                    placeholder="Başlıq..."
                                                                    type="text"
                                                                    invalid={formErrors?.article_lang[index]?.title?.length > 0}
                                                                />
                                                                <FormFeedback>
                                                                    {formErrors?.article_lang[index]?.title}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="description">
                                                                    Qısa Başlıq
                                                                </Label>
                                                                <Input
                                                                    id="description"
                                                                    onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                    name="description"
                                                                    value={oneRecord[index] && oneRecord[index].description}
                                                                    placeholder="Qısa Başlıq..."
                                                                    type="text"
                                                                    invalid={formErrors?.article_lang[index]?.description?.length > 0}
                                                                />
                                                                <FormFeedback>
                                                                    {formErrors?.article_lang[index]?.description}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="content">
                                                                    Kontent
                                                                </Label>
                                                                <CKEditor
                                                                    id="editor-chronology"
                                                                    editor={ClassicEditor}
                                                                    data={contents?.[index] || " "}
                                                                    onChange={(event, editor) => handleContent(event, editor, index)}
                                                                    onInit={editor => {

                                                                    }}
                                                                    config={{
                                                                        ckfinder: {
                                                                            uploadUrl: URL + '/chronology/editor-image-upload',
                                                                        }
                                                                    }}
                                                                />
                                                                <Input type="hidden" invalid={true} />
                                                                <FormFeedback>
                                                                    {formErrors?.article_lang[index]?.content}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>
                                    ))}
                                    {/* {!isMain && (
                                        <FormGroup>
                                            <Label for="dates">
                                                Gün
                                            </Label>
                                            <Select
                                                onChange={setDateSelect2}
                                                options={datesOptions}
                                                value={dateSelect2}
                                                placeholder="Gün"
                                                styles={{
                                                    control: styles => {
                                                        if (formErrors?.article?.date?.length > 0)
                                                            return { ...styles, border: '1px solid #FB6442' }
                                                        return styles
                                                    }
                                                }}
                                            />
                                            <Input type="text" invalid={true} style={{ display: 'none' }} />
                                            <FormFeedback>
                                                {formErrors?.article?.date}
                                            </FormFeedback>
                                        </FormGroup>)
                                    } */}
                                    <FormGroup>
                                        <Label>
                                            Baş vermə yeri
                                        </Label>
                                        <Select
                                            name="place_id"
                                            onChange={(e) => setPlaceSelect(e)}
                                            value={placeSelect}
                                            options={placeOptions}
                                            isMulti
                                            placeholder="Başvermə yeri"
                                            styles={{
                                                control: styles => {
                                                    if (formErrors?.article?.place?.length > 0)
                                                        return { ...styles, border: '1px solid #FB6442' }
                                                    return styles
                                                }
                                            }}
                                        />
                                        <Input type="text" invalid={true} style={{ display: 'none' }} />
                                        <FormFeedback>
                                            {formErrors?.article?.place}
                                        </FormFeedback>
                                    </FormGroup>


                                    <FormGroup>
                                        <Label for="link">
                                            Link
                                        </Label>
                                        <Input
                                            id="link"
                                            name="link"
                                            placeholder="Link..."
                                            type="text"
                                            onChange={(e) => setLink(e.target.value)}
                                            value={link && link}
                                        />
                                    </FormGroup>

                                    {/* <FormGroup>
                                        <Label for="time">
                                            Tarix
                                        </Label>
                                        <style>
                                            {TimerPickerCss}
                                        </style>
                                        <TimePicker
                                            value={time && moment(time, "HH:mm")}
                                            onChange={(e) => {
                                                setTime(moment(e['_d'], "HH:mm").format("HH:mm"))
                                            }}
                                            placeholder="Tarix"
                                            format="HH:mm"
                                            showSecond={false}
                                            allowEmpty={false}
                                            clearIcon={null}
                                        />
                                        <Input invalid={true} style={{ display: 'none' }} />
                                        <FormFeedback>
                                            {formErrors?.article?.time}
                                        </FormFeedback>
                                    </FormGroup> */}

                                    <FormGroup>
                                        <Label for="image">
                                            Şəkil
                                        </Label>
                                        {chronologyImage && <img style={{ display: 'block', marginBottom: 10 }} width={120} height={120} src={`${MEDIA_URL}${chronologyImage}?${new Date().getTime()}`} />}
                                        <CustomInput
                                            id="image"
                                            multiple
                                            label="Şəkil seçmək"
                                            onChange={(e) => handleImagePreview(e)}
                                            name="image"
                                            type="file"
                                            invalid={formErrors?.article?.file?.length > 0}
                                        >
                                            <FormFeedback>
                                                {formErrors?.article?.file}
                                            </FormFeedback>
                                        </CustomInput>
                                    </FormGroup>
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>


                <DeleteModal
                    isOpen={deleteModal}
                    toggleDeleteModal={toggleDeleteModal}
                    title={"Hadisəni silmək"}
                    bodyText={"Bu hadisəni silmək istədiyinizə əminsiniz?"}
                    itemID={itemID}
                    deleteAction={deleteArticle} />
            </Container>
        </>
    );
};

export default Chronology;