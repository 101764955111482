import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Container,
    Row,
    FormGroup,
    Input,
    Col,
    Form,
    TabPane,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Modal,
    CustomInput,
    FormFeedback
} from "reactstrap";
import classnames from 'classnames'

import { MEDIA_URL, URL, EXTENSIONS } from '../../config'
// core components
import GeneralTable from '../components/GeneralTable'
import DeleteModal from '../components/DeleteModal'
import GeneralPagination from "../components/GeneralPagination";
import { AuthContext } from "../../context";

const Tables = () => {
    const [allRecords, setallRecords] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [oneRecord, setoneRecord] = useState([])
    const [file, setFile] = useState(undefined)
    const [medalImage, setMedalImage] = useState()
    const [query, setQuery] = useState()
    const [sort, setSort] = useState({ field: "", order: "" })
    const [languages, setLanguages] = useState([])
    const [formErrors, setFormErrors] = useState({ medal: {}, medal_lang: [] })

    const authContext = useContext(AuthContext)
    const token = JSON.parse(localStorage.getItem("token"))

    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setLanguages(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const handleImagePreview = (e) => {
        e.preventDefault()
        let image_as_file = e.target.files[0];
        setFile(image_as_file)
    }

    const handleQuery = (e) => {
        let value = e.target.value
        if (value.length >= 3) {
            fetchallRecords(1, null, value)
        }
        if (value.length < query?.length) {
            fetchallRecords(1)
        }
        setQuery(value)
    }

    const handleSort = (field) => {
        let order;
        if (sort.order === "asc" && sort.field === field)
            order = "desc";
        else {
            order = "asc"
        }
        let orderObj = { field: field, order: order }
        setSort(orderObj)
        fetchallRecords(1, orderObj, query)
    }

    const getIcon = (field) => {
        if (field === sort.field) {
            switch (sort.order) {
                case "asc":
                    return (<i className="fas fa-angle-up" />)
                case "desc":
                    return (<i className="fas fa-angle-down" />)
            }
        }
    }

    const handleChange = (e, index, lang_id) => {
        e.preventDefault()
        let updatedData

        if (oneRecord[index]) {
            updatedData = [...oneRecord.slice(0, index), { ...oneRecord[index], [e.target.name]: e.target.value }, ...oneRecord.slice(index + 1)]
        }
        else {
            if (e.target.value)
                updatedData = [...oneRecord, { [e.target.name]: e.target.value, lang_id: lang_id }]
        }
        setoneRecord(updatedData)
    };

    const toggle = (index) => {
        setActiveTab(index)
    };

    const toggleCreateUpdateModal = () => {
        if (!modalOpen) {
            getLanguages()
        }
        setModalOpen(!modalOpen)
        setFile("")
        setMedalImage()
        setoneRecord([])
        setActiveTab(1)
        setFormErrors({ medal: {}, medal_lang: [] })
    }
    const changePage = (page) => {
        fetchallRecords(page, sort)
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }


    const fetchallRecords = (page = 1, orderObj, query) => {
        let url = `${URL}/medalaz/all?page=${page}`
        if (orderObj?.field) {
            url += `&field=${orderObj.field}&order=${orderObj.order}`
        }
        if (query) {
            url += `&q=${query}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setallRecords(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }


    const deletemedal = (id) => {
        fetch(`${URL}/medal/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchallRecords(allRecords['current_page'], sort)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const getExtensionFromFilename = (filename) => {
        let splittedFilename = filename.split(".")
        // if (splittedFilename.length > 2) {
        //     return splittedFilename.slice(-2).join('.')
        // }
        return splittedFilename.slice(-1).join('.')
    }

    const validateMedal = () => {
        let obj_lang = oneRecord
        let errors = { medal: {}, medal_lang: [] }
        let valid = true
        errors.medal_lang[0] = {}
        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.title === undefined || obj_lang[0]?.title == '') {
            errors.medal_lang[0] = { ...errors.medal_lang[0], title: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!obj_lang || !obj_lang[0] || obj_lang[0]?.description === undefined || obj_lang[0]?.description == '') {
            errors.medal_lang[0] = { ...errors.medal_lang[0], description: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }

        if (!file) {
            errors.medal = { file: 'Bu xananı doldurmaq lazımdır!' }
            valid = false
        }
        else {
            if (!EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
                errors.medal = { file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
                valid = false
            }
        }
        setFormErrors(errors)
        return valid
    }

    const validateMedalUpdate = () => {
        let obj_lang = oneRecord
        let errors = { medal: {}, medal_lang: [] }
        let valid = true

        for (let i = 0; i < obj_lang.length; i++) {
            errors.medal_lang[i] = {}
            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.title === undefined || obj_lang[i]?.title == '') {
                errors.medal_lang[i] = { ...errors.medal_lang[i], title: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }

            if (!obj_lang || !obj_lang[i] || obj_lang[i]?.description === undefined || obj_lang[i]?.description == '') {
                errors.medal_lang[i] = { ...errors.medal_lang[i], description: 'Bu xananı doldurmaq lazımdır!' }
                valid = false
            }
        }

        if (file) {
            if (!EXTENSIONS.includes(getExtensionFromFilename(file.name))) {
                errors.medal = { file: 'Yalnız bu jpg, jpeg, png, gif, bmp formatlardan birində fayl yükləyə bilərsiniz!' }
                valid = false
            }
        }
        setFormErrors(errors)
        return valid
    }


    const submitForm = (e) => {
        let validate = toCreate ? validateMedal : validateMedalUpdate
        if (validate()) {
            let formData = new FormData()
            if (file)
                formData.append('file', file)
            formData.append('obj_in', `{
                                "medal": {
                                    "image": ""
                                },
                                "medal_lang":${JSON.stringify(oneRecord)}
                                }`
            )
            let url = toCreate ? `${URL}/medal/` : `${URL}/medal/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                fetchallRecords(allRecords['page'], sort)
                toggleCreateUpdateModal()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
    }

    const createModal = () => {
        setToCreate(true)
        toggleCreateUpdateModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/medal/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setMedalImage(result['image'])
            setoneRecord(result['medal_lang'])
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
        getLanguages()
        setItemID(id)
        setToCreate(false)
        toggleCreateUpdateModal()
    }


    useEffect(() => {
        fetchallRecords()
    }, [])




    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Medallar</h3>
                                <FormGroup>
                                    <Input
                                        id="search"
                                        onChange={(e) => handleQuery(e)}
                                        value={query}
                                        placeholder="Axtarış..."
                                        type="text"
                                    />
                                </FormGroup>
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Medal əlavə et</Button>
                            </CardHeader>
                            <GeneralTable
                                columns={[
                                    { title: 'ID', field: 'id' },
                                    { title: 'Başlıq', field: 'title', count: 40 },
                                    { title: 'Qısa Başlıq', field: 'description', count: 40 },
                                    { title: 'Şəkil', field: 'image', notSortable: true }
                                ]}
                                handleSort={handleSort}
                                getIcon={getIcon}
                                toggleUpdateModal={updateModal}
                                toggleDeleteModal={toggleDeleteModal}
                                data={allRecords}
                            />
                            <CardFooter className="py-4">
                                <GeneralPagination
                                    data={allRecords}
                                    changePage={changePage}
                                />
                            </CardFooter>
                        </Card>
                    </div>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleCreateUpdateModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Medal yaratmaq
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col xs="6" sm="4" md="4">
                                <Nav tabs vertical pills>
                                    {languages && languages.map((item, index) => (
                                        <div style={{ marginTop: "10px" }}>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === index + 1 })}
                                                    onClick={() => {
                                                        toggle(index + 1);
                                                    }}
                                                >
                                                    {String(item['short_name']).toUpperCase()}
                                                    {Object.keys(formErrors?.medal_lang[index] || {}).length > 0 &&
                                                        <i
                                                            style={{ color: 'red' }}
                                                            className="ni ni-fat-remove"
                                                        />
                                                    }
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    ))}
                                </Nav>
                            </Col>

                            <Col xs="6" sm="6" md="6">
                                <TabContent activeTab={activeTab}>
                                    {languages && languages.map((language_data, index) => (
                                        <TabPane tabId={index + 1}>
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <Form onSubmit={(e) => submitForm(e)}>
                                                            <FormGroup>
                                                                <Label for="title">
                                                                    Başlıq
                                                                </Label>
                                                                <Input
                                                                    id="title"
                                                                    onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                    name="title"
                                                                    value={oneRecord[index] && oneRecord[index].title}
                                                                    placeholder="Başlıq"
                                                                    type="text"
                                                                    invalid={formErrors?.medal_lang[index]?.title?.length > 0}
                                                                />
                                                                <FormFeedback>
                                                                    {formErrors?.medal_lang[index]?.title}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="description">
                                                                    Qısa Başlıq
                                                                </Label>
                                                                <Input
                                                                    id="description"
                                                                    onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                    name="description"
                                                                    value={oneRecord[index] && oneRecord[index].description}
                                                                    placeholder="Qısa Başlıq"
                                                                    type="text"
                                                                    invalid={formErrors?.medal_lang[index]?.description?.length > 0}
                                                                />
                                                                <FormFeedback>
                                                                    {formErrors?.medal_lang[index]?.description}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>
                                    ))}
                                    <FormGroup>
                                        <Label for="image">
                                            Şəkil
                                        </Label>
                                        {medalImage && <img style={{ display: 'block', marginBottom: 10 }} width={120} height={120} src={`${MEDIA_URL}${medalImage}?${new Date().getTime()}`} />}

                                        <CustomInput
                                            id="image"
                                            multiple
                                            label="Şəkil seçmək"
                                            onChange={(e) => handleImagePreview(e)}
                                            name="image"
                                            type="file"
                                            invalid={formErrors?.medal?.file?.length > 0}
                                        >
                                            <FormFeedback>
                                                {formErrors?.medal?.file}
                                            </FormFeedback>
                                        </CustomInput>
                                    </FormGroup>
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>

                <DeleteModal
                    isOpen={deleteModal}
                    toggleDeleteModal={toggleDeleteModal}
                    title={"Medalı silmək"}
                    bodyText={"Bu medalı silmək istədiyinizə əminsiniz?"}
                    itemID={itemID}
                    deleteAction={deletemedal} />
            </Container>
        </>
    );
};

export default Tables;