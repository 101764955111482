import { Modal, Button } from "reactstrap"


const DeleteModal = ({ isOpen, toggleDeleteModal, title, bodyText, itemID, deleteAction }) => {
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => toggleDeleteModal()}
            backdrop={'static'}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {title}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleDeleteModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {bodyText}
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleDeleteModal()}
                >
                    Bağla
                </Button>
                <Button color="primary" type="button" onClick={() => {
                    deleteAction(itemID)
                    toggleDeleteModal()
                }}>
                    Silmək
                </Button>
            </div>
        </Modal>
    )
}

export default DeleteModal