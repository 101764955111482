import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Container,
    Row,
    FormGroup,
    FormFeedback,
    Input,
    Col,
    Form,
    TabPane,
    TabContent,
    NavLink,
    NavItem,
    Nav,
    Modal
} from "reactstrap";
import { URL } from '../../config'
import classnames from 'classnames'
// core components
import GeneralTable from '../components/GeneralTable'
import DeleteModal from '../components/DeleteModal'
import { AuthContext } from "../../context";

const Info = () => {
    const [allRecords, setallRecords] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [languages, setLanguages] = useState([])
    const [oneRecord, setoneRecord] = useState({ info_lang: [] })
    const [formErrors, setFormErrors] = useState({ info_lang: [] })
    const [query, setQuery] = useState()
    const [sort, setSort] = useState({ field: "", order: "" })

    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)
    const info_type = 'soldier_type'

    const handleChange = (e, index, lang_id) => {
        let updatedData = { ...oneRecord }
        let updatedRecord = updatedData['info_lang']
        if (updatedRecord != undefined && updatedRecord[index]) {
            updatedData.info_lang = [...updatedRecord.slice(0, index), { ...updatedRecord[index], title: e.target.value }, ...updatedRecord.slice(index + 1)]
        }
        else {
            updatedData.info_lang = [...(updatedRecord || []), { title: e.target.value, lang_id: lang_id }]
        }
        setoneRecord(updatedData)
    };

    const validate = () => {
        let obj = oneRecord.info_lang
        let errors = { ...formErrors }
        let generalValid = true
        errors.valid = true
        if (obj && obj[0]) {
            if (obj[0].title === undefined || obj[0].title == '') {
                errors.info_lang = [{ title: 'Bu xananı doldurmaq lazımdır!' }]
                errors.valid = false
                generalValid = false
            }
        }
        else {
            errors.info_lang = [{ title: 'Bu xananı doldurmaq lazımdır!' }]
            errors.valid = false
            generalValid = false
        }
        setFormErrors(errors)
        return generalValid
    }


    const validateUpdate = () => {
        let obj = oneRecord.info_lang
        let errors = { ...formErrors }
        let generalValid = true
        if (obj) {
            for (let i = 0; i < obj.length; i++) {
                let errorMessage = ""
                let valid = true
                if (obj[i].title === undefined || obj[i].title == '') {
                    errorMessage = 'Bu xananı doldurmaq lazımdır!'
                    valid = false
                    generalValid = false
                }
                errors.info_lang[i] = { title: errorMessage, valid: valid }
            }
        }
        else {
            for (let i = 0; i < obj.length; i++) {
                errors.info_lang[i] = { title: 'Bu xananı doldurmaq lazımdır!', valid: false }
                errors.valid = false
                generalValid = false
            }
        }
        setFormErrors(errors)
        return generalValid
    }


    const handleQuery = (e) => {
        let value = e.target.value
        if (value.length >= 3) {
            fetchallRecords(1, null, value)
        }
        if (value.length < query?.length) {
            fetchallRecords(1)
        }
        setQuery(value)
    }

    const toggle = (index) => {
        setActiveTab(index)
    };

    const toggleCreateUpdateModal = () => {
        setModalOpen(!modalOpen)
        setActiveTab(1)
        setoneRecord([])
        setFormErrors({ info_lang: { name: '' }, valid: true })
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const fetchallRecords = (page = 1, orderObj = null, query = null) => {
        let url = `${URL}/info/az/all?info_type=${info_type}`
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setallRecords({ items: result })
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const getIcon = (field) => {
        if (field === sort.field) {
            switch (sort.order) {
                case "asc":
                    return (<i className="fas fa-angle-up" />)
                case "desc":
                    return (<i className="fas fa-angle-down" />)
            }
        }
    }

    const handleSort = (field) => {
        let order;
        if (sort.order === "asc" && sort.field === field)
            order = "desc";
        else {
            order = "asc"
        }
        let orderObj = { field: field, order: order }
        setSort(orderObj)
        fetchallRecords(1, orderObj, query)
    }


    const deleteinfo = (id) => {
        fetch(`${URL}/info/delete/${id}`, {
            method: 'DELETE', headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => res.json()).then(msg => {
            fetchallRecords(allRecords['current_page'], sort, query)
            toggleDeleteModal()
        })
    }


    const submitForm = () => {
        let validationCheck = toCreate ? validate : validateUpdate
        if (validationCheck()) {
            let url = toCreate ? `${URL}/info/` : `${URL}/info/update/${itemID}`
            let method = toCreate ? 'POST' : 'PUT'
            oneRecord.info = { info_type: info_type }
            fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(oneRecord)
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            })
                .then(result => {
                    //Successful request processing
                    fetchallRecords(allRecords['current_page'], sort)
                    toggleCreateUpdateModal()
                }).catch(error => {
                    error.json().then((body) => {
                        // setErr(body['detail'])
                    });
                })
        }
    }

    const createModal = () => {
        setToCreate(true)
        toggleCreateUpdateModal()
    }

    const updateModal = (id) => {
        fetch(`${URL}/info/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setoneRecord(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
        setItemID(id)
        setToCreate(false)
        toggleCreateUpdateModal()
    }

    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setLanguages(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }


    useEffect(() => {
        getLanguages()
        fetchallRecords()
    }, [])


    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Əsgər tipləri</h3>
                                {/* <FormGroup>
                                    <Input
                                        id="search"
                                        onChange={(e) => handleQuery(e)}
                                        value={query}
                                        placeholder="Axtarış..."
                                        type="text"
                                    />
                                </FormGroup> */}
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Əsgər tipi əlavə et</Button>
                            </CardHeader>
                            <GeneralTable
                                columns={[
                                    { title: 'ID', field: 'id' },
                                    { title: 'Adı', field: 'title' }
                                ]}
                                handleSort={handleSort}
                                getIcon={getIcon}
                                toggleUpdateModal={updateModal}
                                toggleDeleteModal={toggleDeleteModal}
                                data={allRecords}
                            />
                            {/* <CardFooter className="py-4">
                                <GeneralPagination
                                    data={allRecords}
                                    changePage={changePage}
                                />
                            </CardFooter> */}
                        </Card>
                    </div>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleCreateUpdateModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Əsgər tipi yaratmaq
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col xs="6" sm="4" md="4">
                                <Nav tabs vertical pills>
                                    {languages && languages.map((item, index) => (
                                        <div style={{ marginTop: "10px" }}>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === index + 1 })}
                                                    onClick={() => {
                                                        toggle(index + 1);
                                                    }}
                                                >
                                                    {String(item['short_name']).toUpperCase()}
                                                    {formErrors.info_lang[index]?.title.length > 0 &&
                                                        <i style={{ color: 'red' }} className="ni ni-fat-remove" />
                                                    }
                                                </NavLink>
                                            </NavItem>
                                        </div>
                                    ))}
                                </Nav>
                            </Col>
                            <Col xs="6" sm="6" md="6">
                                <TabContent activeTab={activeTab}>
                                    {languages && languages.map((language_data, index) => (
                                        <TabPane tabId={index + 1}>
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <h5>Cari dil: {String(language_data['short_name']).toUpperCase()}</h5>
                                                            <Input
                                                                invalid={formErrors.info_lang[index]?.title.length > 0}
                                                                id="exampleFormControlInput1"
                                                                onChange={(e) => handleChange(e, index, language_data['id'])}
                                                                value={oneRecord['info_lang'] && oneRecord['info_lang'][index]?.title}
                                                                placeholder="əsgər tipinin adi..."
                                                                type="text"
                                                            />

                                                            <FormFeedback>
                                                                {formErrors?.info_lang[index]?.title}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>



                <DeleteModal
                    isOpen={deleteModal}
                    toggleDeleteModal={toggleDeleteModal}
                    title={"info silmək"}
                    bodyText={"Bu əsgər tipini silmək istədiyinizə əminsiniz?"}
                    itemID={itemID}
                    deleteAction={deleteinfo} />
            </Container>
        </>
    );
};

export default Info;