import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "./context"
import { URL } from './config'
import Login from "views/examples/Login"
import ChangePassword from "views/examples/ChangePassword"
import routes from "routes";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar.js";


const App = (props) => {
    const [user, setUser] = useState();
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [checked, setChecked] = useState(false)


    const fetchUserFromToken = (token) => {
        let url = `${URL}/user/me`
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        })
            .then(result => {
                setLoggedIn(true)
                setUser(result)
                setChecked(true)
            }).catch(error => {
                if (error.status === 401) {
                    setLoggedIn(false)
                    setChecked(true)
                }
            })
    }


    const login = (data) => {
        localStorage.setItem("token", JSON.stringify(data['access_token']));
        localStorage.setItem("refresh_token", JSON.stringify(data['refresh_token']));
        const token = JSON.parse(localStorage.getItem("token"))
        fetchUserFromToken(token)
        setLoggedIn(true)
    }

    const logout = () => {
        localStorage.clear()
        setUser({});
        setLoggedIn(false)
    }


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"))
        if (token) {
            fetchUserFromToken(token)
        }
        else {
            setLoggedIn(false)
            setChecked(true)
        }
    }, [])


    return (
        <AuthContext.Provider value={{ isLoggedIn: isLoggedIn, user: user, login: login, logout: logout }}>
            {checked &&
                <BrowserRouter>
                    <Switch>
                        {isLoggedIn &&
                            routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={'/admin' + route.path}
                                    exact={true}
                                    render={() => {
                                        const MyComponent = route.component
                                        return (
                                            <>
                                                <Sidebar
                                                    {...props}
                                                    routes={routes}
                                                    logo={{
                                                        innerLink: "/admin",
                                                        imgSrc: require("./assets/img/brand/argon-react.png").default,
                                                        imgAlt: "...",
                                                    }}
                                                />
                                                <AdminNavbar {...props} />
                                                <MyComponent />
                                            </>
                                        )

                                    }}

                                />
                            ))
                        }
                        <Route path={"/admin/login"} render={() => {
                            return !isLoggedIn ? <Login /> : <Redirect to={"/admin/menu"} />
                        }} exact />
                        {!isLoggedIn && <Redirect to={"/admin/login"} />}
                        {isLoggedIn && <Route path={"/admin/change-password"} component={ChangePassword} />}
                        {isLoggedIn &&<Route render={()=><Redirect to={"/admin/menu"} />}/>}
                    </Switch>
                </BrowserRouter>
            }
        </AuthContext.Provider >
    )
}

export default App