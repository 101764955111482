import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Button,
    Container,
    Modal,
    Row,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    CustomInput,
    Table,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import Select from 'react-select'
import { MEDIA_URL, URL, EXTENSIONS } from "../../config";
import DeleteModal from '../components/DeleteModal'
import GeneralPagination from "../components/GeneralPagination";
import DeleteImage from "views/components/DeleteImage";
import { AuthContext } from "../../context";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build'




const Tables = () => {
    const [allRecords, setallRecords] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [fileModal, setFileModal] = useState(false)
    const [itemID, setItemID] = useState()
    const [toCreate, setToCreate] = useState(true)
    const [sort, setSort] = useState({ field: "", order: "" })
    const [query, setQuery] = useState()
    const initialFormErrors = { soldier: {}, soldier_lang: {} }
    const [formErrors, setFormErrors] = useState(initialFormErrors)

    const [placeOptions, setPlaceOptions] = useState()
    const [placeOptionDict, setPlaceOptionDict] = useState({})
    const [rankOptions, setRankOptions] = useState()
    const [rankOptionDict, setRankOptionDict] = useState()
    const [medalOptions, setMedalOptions] = useState()
    const [medalOptionDict, setMedalOptionDict] = useState()

    const [videoTypeOptions, setVideoTypeOptions] = useState([])
    const [educationOptions, setEducationOptions] = useState()
    const [educationOptionsDict, setEducationOptionsDict] = useState()
    const [familyTypeOptions, setFamilyTypeOptionss] = useState()
    const [familyTypeOptionsDict, setFamilyTypeOptionsDict] = useState()
    const [dutyTypeOptions, setDutyTypeOptions] = useState()
    const [dutyTypeOptionsDict, setDutyTypeOptionsDict] = useState()
    const [troopTypeOptions, setTroopTypeOptions] = useState()
    const [troopTypeOptionsDict, setTroopTypeOptionsDict] = useState()

    const [bio, setBio] = useState('')
    const [videos, setVideos] = useState([{}])
    const [images, setImages] = useState([{}])
    const [dbImages, setDbImages] = useState([])
    const [soldier, setSoldier] = useState({})
    const [soldierLang, setSoldierLang] = useState({ biography: '' })
    const [injuryPlaces, setInjuryPlaces] = useState([{}])
    const [battlePlaces, setBattlePlaces] = useState([{}])
    const [places, setPlaces] = useState({})
    const [translationModal, setTranslationModal] = useState(false)
    const [translations, setTranslations] = useState([])
    const [languages, setLanguages] = useState([])
    const [addLanguageModal, setAddLanguageModal] = useState(false)
    const [langId, setLangId] = useState()
    const [languageShort, setLanguageShort] = useState()
    const [qaziID, setQaziID] = useState(null)
    const [gallery, setGallery] = useState()
    const [activeTab, setActiveTab] = useState(1)

    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)


    const getLanguages = () => {
        fetch(`${URL}/language/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setLanguages(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const getVideoTypes = async () => {
        const result = [
            { value: "youtube", label: "youtube" },
            { value: "other", label: "other" }]
        setVideoTypeOptions(result)
    }

    const getOptions = async (url, setOptionsDict, setOptions) => {
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            if (!response.ok) throw response
            const all_data = await response.json()
            let all_data_as_dict = {}
            for (let i = 0; i < all_data.length; i++) {
                all_data_as_dict[all_data[i]['id']] = { value: all_data[i]['id'], label: all_data[i]['title'] }
            }
            setOptionsDict(all_data_as_dict)
            const result = all_data.map(item => {
                return { value: item['id'], label: item['title'] }
            })
            setOptions(result)
        } catch (error) {
            if (error.status == 401) {
                authContext.logout()
            }
        }
    }

    const getPlaceOptions = async () => {
        let result = await getOptions(`${URL}/placeaz/all`, setPlaceOptionDict, setPlaceOptions)
        return result
    }

    const getRanksOptions = async () => {
        await getOptions(`${URL}/rankaz/all`, setRankOptionDict, setRankOptions)
    }

    const getMedalOptions = async () => {
        await getOptions(`${URL}/medalaz/all`, setMedalOptionDict, setMedalOptions)
    }

    const getTroopOptions = async () => {
        await getOptions(`${URL}/info/az/all?info_type=troop_type`, setTroopTypeOptionsDict, setTroopTypeOptions)
    }

    const getEducationOptions = async () => {
        await getOptions(`${URL}/info/az/all?info_type=education`, setEducationOptionsDict, setEducationOptions)
    }

    const getFamilyOptions = async () => {
        await getOptions(`${URL}/info/az/all?info_type=family_type`, setFamilyTypeOptionsDict, setFamilyTypeOptionss)
    }

    const getDutyOptions = async () => {
        await getOptions(`${URL}/info/az/all?info_type=duty`, setDutyTypeOptionsDict, setDutyTypeOptions)
    }

    const getGallery = () => {
        fetch(URL + '/soldier/soldier-gallery', {
            method: 'GET',
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setGallery(result)
        }).catch(error => {

        })
    }

    const handleChange = (e) => {
        let mySoldier = { ...soldier }
        if (e.target.value == '') {
            delete mySoldier[e.target.name]
        }
        else {
            mySoldier[e.target.name] = e.target.value
        }
        setSoldier(mySoldier)
    }

    const handleChangeDate = (value, name) => {
        let mySoldier = { ...soldier }
        if (value instanceof Date) {
            let formattedDate = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
            mySoldier[name] = formattedDate
            setSoldier(mySoldier)
        }
    }

    const handleChangeLang = (e) => {
        let mySoldierLang = { ...soldierLang }
        mySoldierLang[e.target.name] = e.target.value
        setSoldierLang(mySoldierLang)
    };

    const handleChangePlace = (name, e, action = null) => {
        let soldierPlaces = { ...places }
        if (!soldierPlaces[name]) soldierPlaces[name] = {}
        if (action) {
            soldierPlaces[name][action['name']] = e['value']
        }
        else {
            soldierPlaces[name][e.target.name] = e.target.value
        }
        setPlaces(soldierPlaces)
    }

    const handleChangeBattlePlaces = (e, action, index) => {
        let soldierBattlePlaces = [...battlePlaces]
        if (!soldierBattlePlaces[index]) soldierBattlePlaces[index] = {}
        if (action) {
            soldierBattlePlaces[index][action['name']] = e['value']
        }
        else {
            soldierBattlePlaces[index][e.target.name] = e.target.value
        }
        setBattlePlaces(soldierBattlePlaces)
    }

    const handleChangeInjuryPlaces = (e, type, index) => {
        let soldierInjuryPlaces = [...injuryPlaces]
        if (!soldierInjuryPlaces[index]) soldierInjuryPlaces[index] = {}
        switch (type) {
            case 'text':
                soldierInjuryPlaces[index][e.target.name] = e.target.value
                break

            case 'select':
                soldierInjuryPlaces[index]['place_id'] = e['value']
                break

            case 'date':
                let value = e
                if (value instanceof Date) {
                    let formattedDate = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`;
                    soldierInjuryPlaces[index]['injury_date'] = formattedDate
                }

        }
        setInjuryPlaces(soldierInjuryPlaces)
    }

    const handleChangeVideo = (e, action = null, index) => {
        let soldierVideos = [...videos]
        if (!soldierVideos[index]) soldierVideos[index] = {}
        if (action) {
            soldierVideos[index][action['name']] = e['value']
        }
        else {
            soldierVideos[index][e.target.name] = e.target.value
        }
        setVideos(soldierVideos)
    }

    const handleChangeImage = (e) => {
        let soldierImages = { ...images }
        switch (e.target.name) {
            case 'profile':
                soldierImages['profile'] = {}
                soldierImages['profile'] = { file: e.target.files[0], rank: 1 }
                break
            case 'other':
                soldierImages['other'] = []
                for (let i = 0; i < e.target.files.length; i++) {
                    soldierImages['other'].push({ file: e.target.files[i], rank: 2 })
                }
                break
        }
        setImages(soldierImages)
    }

    const handleSelect = (value, action) => {
        let mySoldier = { ...soldier }
        if (Array.isArray(value)) {
            mySoldier[action['name']] = value.map(item => item['value'])
        }
        else {
            mySoldier[action['name']] = value['value']
        }
        setSoldier(mySoldier)
    }

    const handleQuery = (e) => {
        let value = e.target.value
        if (value.length >= 3) {
            fetchallRecords(1, null, value, qaziID)
        }
        if (value.length < query?.length) {
            fetchallRecords(1, null, null, qaziID)
        }
        setQuery(value)
    }

    const handleSort = (field) => {
        let order;
        if (sort.order === "asc" && sort.field === field)
            order = "desc";
        else {
            order = "asc"
        }
        let orderObj = { field: field, order: order }
        setSort(orderObj)
        fetchallRecords(1, orderObj, query, qaziID)
    }

    const getIcon = (field) => {
        if (field === sort.field) {
            switch (sort.order) {
                case "asc":
                    return (<i className="fas fa-angle-up" />)
                case "desc":
                    return (<i className="fas fa-angle-down" />)
            }
        }
    }

    const toggleCreateUpdateModal = () => {
        if (!modalOpen) {
            getPlaceOptions()
            getRanksOptions()
            getMedalOptions()
            getVideoTypes()
            getTroopOptions()
            getDutyOptions()
            getEducationOptions()
            getFamilyOptions()
        }
        else {
            formsIntialState()
        }
        setModalOpen(!modalOpen)
        setFormErrors(initialFormErrors)
    }

    const toggleTranslationModal = () => {
        if (translationModal) {
            setSoldierLang()
        }
        setTranslationModal(!translationModal)
    }

    const toggleAddLanguageModal = (lang_id) => {
        if (addLanguageModal) {
            formsIntialState()
        }
        setLangId(lang_id)
        setAddLanguageModal(!addLanguageModal)
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const toggleFileModal = () => {
        if (!fileModal) {
            getGallery()
        }
        setFileModal(!fileModal)
    }

    const fetchallRecords = (page = 1, orderObj = null, query = null, qaziID) => {
        let url = page > 0 ? `${URL}/soldier/az/all?page=${page}&soldier_type_id=${qaziID}` : `${URL}/soldier/az/all?page=${1}&soldier_type_id=${qaziID}`
        if (orderObj?.field) {
            url += `&field=${orderObj.field}&order=${orderObj.order}`
        }
        if (query) {
            url += `&q=${query}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setallRecords(result)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const changePage = (page) => {
        fetchallRecords(page, null, query, qaziID)
    }

    const deleteSoldier = (id) => {
        fetch(`${URL}/soldier/delete/${id}`, {
            method: 'DELETE', headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchallRecords(allRecords['current_page'], sort, query, qaziID)
            toggleDeleteModal()
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const formsIntialState = () => {
        setSoldier()
        setSoldierLang()
        setImages([{}])
        setVideos([{}])
        setInjuryPlaces([{}])
        setBattlePlaces([{}])
        setPlaces([])
        setBio('')
        setFormErrors(initialFormErrors)
        setDbImages([])
    }

    const validateSoldierLang = () => {
        let obj = { ...soldierLang }
        let errors = {}
        if (obj?.name == undefined || obj?.name == '') {
            errors = { ...errors, name: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.surname == undefined || obj?.surname == '') {
            errors = { ...errors, surname: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.father_name == undefined || obj?.father_name == '') {
            errors = { ...errors, father_name: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (bio == undefined || bio == '') {
            errors = { ...errors, biography: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.school == undefined || obj?.school == '') {
            errors = { ...errors, school: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.hobby == undefined || obj?.hobby == '') {
            errors = { ...errors, hobby: 'Bu xananı doldurmaq lazımdır!' }
        }
        return errors
    }

    const validateSoldier = () => {
        let obj = { ...soldier }
        let errors = {}
        if (toCreate && (!images || images['profile'] == undefined || images['profile'] == {})) {
            errors = { ...errors, profile_image: 'Bu xananı doldurmaq lazımdır!' }
        }
        if (toCreate && images && images['profile']) {
            let filename = images['profile'].file.name
            let extension = filename.split(".").slice(-1)[0]
            if (!EXTENSIONS.includes(extension)) {
                errors = { ...errors, profile_image: 'Şəkil formatı düzgün deyil!' }
            }
        }
        if (obj?.birth_date == undefined || obj?.birth_date == '') {
            errors = { ...errors, birth_date: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.duty_type_id == undefined) {
            errors = { ...errors, duty_type: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.family_type_id == undefined) {
            errors = { ...errors, family_type: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.troop_type_id == undefined) {
            errors = { ...errors, troop_type: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.education_type_id == undefined) {
            errors = { ...errors, education_type: 'Bu xananı doldurmaq lazımdır!' }
        }

        if (obj?.rank_id == undefined) {
            errors = { ...errors, rank: 'Bu xananı doldurmaq lazımdır!' }
        }

        return errors
    }

    const validatePlace = () => {
        let obj = { ...places }
        let errors = {}
        // birth place check
        if (obj?.birth_place?.place_id == undefined) {
            errors.birth_place = 'Bu xananı doldurmaq lazımdır!'
        }

        // battle places check
        for (let i = 0; i < battlePlaces.length; i++) {
            if (battlePlaces[i]?.place_id == undefined) {
                if (!errors.battle_places) errors.battle_places = {}
                errors.battle_places[i] = { ...errors.battle_places[i], place: 'Bu xananı doldurmaq lazımdır!' }
            }
        }
        return errors
    }

    const validateForm = () => {
        let validated = true
        let soldierLangErrors = validateSoldierLang()
        let soldierErrors = validateSoldier()
        let placeErrors = validatePlace()
        let finalErrors = {}
        if (Object.keys(soldierErrors).length !== 0) {
            finalErrors.soldier = soldierErrors
            validated = false
        }
        if (Object.keys(soldierLangErrors).length !== 0) {
            finalErrors.soldier_lang = soldierLangErrors
            validated = false
        }
        if (Object.keys(placeErrors).length !== 0) {
            finalErrors.places = placeErrors
            validated = false
        }
        setFormErrors(finalErrors)
        return validated
    }

    const validateAddLanguageForm = () => {
        let validated = true
        let soldierLangErrors = validateSoldierLang()
        let placeErrors = validatePlace()
        let finalErrors = {}
        if (Object.keys(soldierLangErrors).length !== 0) {
            finalErrors.soldier_lang = soldierLangErrors
            validated = false
        }
        if (Object.keys(placeErrors).length !== 0) {
            finalErrors.places = placeErrors
            validated = false
        }
        setFormErrors(finalErrors)
        return validated
    }

    const submitForm = (e) => {
        if (validateForm()) {
            let obj = {}
            let formData = new FormData()
            if (videos[0] && Object.keys(videos[0]).length > 1) {
                let filtered_videos = []

                for (let video in videos) {
                    let obj = videos[video]
                    if (obj['video_link'] && obj['rank'] && obj['link_type'])
                        filtered_videos.push(videos[video])
                }
                soldier['videos'] = filtered_videos
            }
            if (injuryPlaces[0] && Object.keys(injuryPlaces[0]).length > 1)
                soldier['injury_place'] = injuryPlaces

            soldier['battle_places'] = battlePlaces
            soldier['soldier_type_id'] = qaziID
            for (let place in places) {
                soldier[place] = places[place]
            }
            let finalImages = []
            if (images && images['profile']) {
                formData.append('files', images['profile'].file)
                finalImages.push({ rank: images['profile'].rank })
            }
            if (images && images['other']) {
                for (let image in images['other']) {
                    formData.append('files', images['other'][image].file)
                    finalImages.push({ rank: images['other'][image].rank })
                }
            }
            soldier['images'] = finalImages
            soldierLang['biography'] = bio
            obj['soldier'] = soldier
            obj['soldier_lang'] = soldierLang
            formData.append('obj_in', JSON.stringify(obj))

            let method = toCreate ? 'POST' : 'PUT'
            let url = toCreate ? `${URL}/soldier/` : `${URL}/soldier/update/${itemID}`

            fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                method: method,
                body: formData
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                fetchallRecords(allRecords['current_page'], sort, null, qaziID)
                toggleCreateUpdateModal()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
    }

    const createModal = () => {
        setToCreate(true)
        toggleCreateUpdateModal()
    }

    const getSoldierToUpdate = (languageShort) => {
        let url = `${URL}/soldier/${itemID}/${languageShort}/admin`
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        })
            .then(result => {
                setSoldier(result['soldier'])
                setSoldierLang(result['soldier_lang'])
                setBio(result['soldier_lang']['biography'])
                setPlaces(
                    {
                        birth_place: result['birth_place'],
                        martyred_place: result['martyred_place'],
                        burial_place: result['burial_place']
                    })
                setInjuryPlaces(result['injury_places'])
                setBattlePlaces(result['battle_places'])
                setVideos(result['videos'])
                setDbImages(result['images'])

            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
    }

    const updateModal = (id) => {
        getLanguages()
        setToCreate(false)
        fetch(`${URL}/soldier/soldier-languages/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        })
            .then(result => {
                let updatedResult = {}
                let objects = result['soldier_lang']
                if (objects) {
                    for (let i = 0; i < objects.length; i++) {
                        updatedResult[objects[i]['lang']['id']] = objects[i]
                    }
                }
                setTranslations(updatedResult)
                getSoldierToAddLanguage(id)
                getPlaceOptions()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        setItemID(id)
        toggleTranslationModal()
    }

    const getSoldierToAddLanguage = (id) => {
        fetch(`${URL}/soldier/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            let updatedPlaces = { ...places }
            let birth_place = { place_id: result['birth_place']['place_id'], parent_id: result['birth_place']['id'], description: "" }
            updatedPlaces['birth_place'] = birth_place
            if (result['martyred_place']) {
                let martyred_place = { place_id: result['martyred_place']['place_id'], parent_id: result['martyred_place']['id'], description: "" }
                updatedPlaces['martyred_place'] = martyred_place
            }
            if (result['burial_place']) {
                let burial_place = { place_id: result['burial_place']['place_id'], parent_id: result['burial_place']['id'], description: "" }
                updatedPlaces['burial_place'] = burial_place
            }

            if (result['injury_places']) {
                let injury_places = result['injury_places'].map(item => {
                    return { place_id: item['place_id'], parent_id: item['id'], description: "" }
                })
                setInjuryPlaces(injury_places)
            }

            if (result['battle_places']) {
                let battle_places = result['battle_places'].map(item => {
                    return { place_id: item['place_id'], parent_id: item['id'], description: "" }
                })
                setBattlePlaces(battle_places)
            }
            setPlaces(updatedPlaces)
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }

    const submitAddLanguage = () => {
        if (validateAddLanguageForm()) {
            soldierLang['biography'] = bio
            let obj_in = soldierLang
            for (let place in places) {
                obj_in[place] = places[place]
            }
            obj_in['injury_place'] = injuryPlaces
            obj_in['battle_places'] = battlePlaces
            obj_in['lang_id'] = langId

            fetch(`${URL}/soldier/add-lang/${itemID}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(obj_in)
            }).then(result => {
                if (!result.ok) throw result;
                return result.json();
            }).then(result => {
                toggleAddLanguageModal(null)
                formsIntialState()
            }).catch(error => {
                if (error.status == 401) {
                    authContext.logout()
                }
            })
        }
    }

    const deleteListItems = (id, idFieldName, list, setList, obj, setObj, fieldName) => {
        const newList = list.filter(item => item[idFieldName] !== id)
        let myObj = { ...obj }
        if (!myObj[fieldName]) myObj[fieldName] = [id,]
        else myObj[fieldName].push(id)
        setObj(myObj)
        setList(newList)
    }

    const deleteImage = (id) => {
        deleteListItems(id, 'id', dbImages, setDbImages, soldier, setSoldier, 'deleted_image_ids')
    }

    const deleteVideo = (id) => {
        deleteListItems(id, 'id', videos, setVideos, soldier, setSoldier, 'deleted_video_ids')
    }

    const deleteBattlePlace = (id) => {
        deleteListItems(id, 'soldier_place_id', battlePlaces, setBattlePlaces, soldier, setSoldier, 'deleted_battle_place_ids')
    }

    const handleBio = (event, editor) => {
        const data = editor.getData()
        setBio(data)
    }



    useEffect(() => {
        fetch(`${URL}/info/soldier-type?title=Qazi`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setQaziID(result['id'])
            fetchallRecords(1, null, null, result['id'])
        }).catch(error => {
            if (error.status == 401) {
                authContext.logout()
            }
        })
    }, [])


    const datePickerCss =
        `
        .react-datepicker-wrapper input{
            width: 100%;
            padding: 5px;
            border: 1px solid #C1C1C1;
            border-radius: 5px;
        }
    `

    let columns = [
        { title: 'Ad', field: 'name' },
        { title: 'Soyad', field: 'surname' },
        { title: 'Rütbə', field: 'rank' }]

    return (
        <>
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Qazilər - ( { allRecords?.row_count } )</h3>
                                <FormGroup>
                                    <Input
                                        id="search"
                                        onChange={(e) => handleQuery(e)}
                                        value={query}
                                        placeholder="Axtarış..."
                                        type="text"
                                    />
                                </FormGroup>
                                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Qazi əlavə et</Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th key={100} scope="col" onClick={() => handleSort('id')} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                            #ID {getIcon('id')}
                                        </th>
                                        {
                                            columns.map((item, index) => (
                                                <th key={index} scope="col" onClick={item.notSortable ? undefined : () => handleSort(item.field)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                    {item.title} {getIcon(item.field)}
                                                </th>
                                            ))
                                        }
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allRecords ? allRecords['items']?.map((item, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            {columns.map(column => (
                                                <td style={{ textAlign: 'center' }}>{item[column.field]}</td>
                                            ))}
                                            <td style={{ textAlign: 'center' }}>
                                                <Button className="btn-icon btn-3" color="success" onClick={() => updateModal(item['id'])} type="button">Düzəliş etmək</Button>
                                                <Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(item['id'])} type="button">Silmək</Button>
                                            </td>
                                        </tr>
                                    )
                                    ) :
                                        null}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <GeneralPagination
                                    data={allRecords}
                                    changePage={changePage}
                                />
                            </CardFooter>

                        </Card>
                    </div>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    size="xl"
                    isOpen={modalOpen}
                    toggle={() => toggleCreateUpdateModal()}
                    backdrop={'static'}
                    scrollable={true}
                >
                    <div className="modal-header">
                        <h2 className="modal-title" id="exampleModalLabel">
                            {toCreate ? 'Əlavə etmək' : 'Məlumatları düzəliş etmək'}
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <style>{datePickerCss}</style>
                        <Row>
                            {dbImages && dbImages[0] &&
                                <FormGroup>
                                    <img width={200} src={MEDIA_URL + dbImages[0]['image']} alt="Not found" />
                                </FormGroup>
                            }
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Profil şəkili</Label>
                                <CustomInput
                                    id="image"
                                    multiple
                                    label="Şəkil seçmək"
                                    onChange={(e) => handleChangeImage(e)}
                                    name="profile"
                                    type="file"
                                    invalid={formErrors?.soldier?.profile_image?.length > 0}
                                >
                                    <FormFeedback>
                                        {formErrors?.soldier?.profile_image}
                                    </FormFeedback>
                                </CustomInput>
                            </FormGroup>
                        </Row>
                        <Row xs={3}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Adı</Label>
                                <Input invalid={formErrors?.soldier_lang?.name?.length > 0} type="text" name="name" onChange={(e) => handleChangeLang(e)} value={soldierLang?.name} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.name}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10, borderWidth: 10, borderColor: 'red' }}>
                                <Label>Soyadı</Label>
                                <Input invalid={formErrors?.soldier_lang?.surname?.length > 0} type="text" name="surname" onChange={(e) => handleChangeLang(e)} value={soldierLang?.surname} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.surname}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Ata adı</Label>
                                <Input invalid={formErrors?.soldier_lang?.father_name?.length > 0} type="text" name="father_name" onChange={(e) => handleChangeLang(e)} value={soldierLang?.father_name} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.father_name}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={3}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Doğum tarixi</Label>
                                <DatePicker
                                    showYearDropdown
                                    placeholderText="dd.MM.yyyy"
                                    dateFormat="dd.MM.yyyy"
                                    selected={soldier?.birth_date && new Date(soldier?.birth_date)}
                                    onChange={(date) => handleChangeDate(date, 'birth_date')}
                                />
                                <Input type="hidden" invalid={true} />
                                <FormFeedback>
                                    {formErrors?.soldier?.birth_date}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Doğum yeri
                                </Label>
                                <Select
                                    name="place_id"
                                    value={places['birth_place'] && placeOptionDict && placeOptionDict[places['birth_place']['place_id']]}
                                    onChange={(value, action) => handleChangePlace('birth_place', value, action)}
                                    options={placeOptions}
                                    placeholder="Doğum yeri"
                                    invalid={formErrors?.places?.birth_place?.length > 0}
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.places?.birth_place)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.places?.birth_place}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Doğum yeri detallı
                                </Label>
                                <Input type="text"
                                    name="description"
                                    onChange={(e) => handleChangePlace('birth_place', e)}
                                    value={places['birth_place'] && places['birth_place']['description']} />
                            </FormGroup>
                        </Row>
                        <Row xs={3}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Təhsili
                                </Label>
                                <Select
                                    value={soldier && educationOptionsDict && educationOptionsDict[soldier['education_type_id']]}
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="education_type_id"
                                    options={educationOptions}
                                    placeholder="Təhsili"
                                    invalid={formErrors?.soldier?.education_type?.length > 0}
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.soldier?.education_type)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.soldier?.education_type}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Ailə vəziyyəti
                                </Label>
                                <Select
                                    value={soldier && familyTypeOptionsDict && familyTypeOptionsDict[soldier['family_type_id']]}
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="family_type_id"
                                    options={familyTypeOptions}
                                    placeholder="Ailə vəziyyəti"
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.soldier?.family_type)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.soldier?.family_type}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Uşaqlarının sayı
                                </Label>
                                <Input
                                    type="number"
                                    name="number_of_children"
                                    onChange={(e) => handleChange(e)}
                                    value={soldier?.number_of_children}
                                />

                            </FormGroup>
                        </Row>
                        <Row xs={3}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Oxuduğu məktəb</Label>
                                <Input invalid={formErrors?.soldier_lang?.school?.length > 0} type="text" name="school" onChange={(e) => handleChangeLang(e)} value={soldierLang?.school} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.school}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Ali təhsil müəssisəsi</Label>
                                <Input invalid={formErrors?.soldier_lang?.university?.length > 0} type="text" name="university" onChange={(e) => handleChangeLang(e)} value={soldierLang?.university} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.university}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>İş yeri</Label>
                                <Input invalid={formErrors?.soldier_lang?.work_place?.length > 0} type="text" name="work_place" onChange={(e) => handleChangeLang(e)} value={soldierLang?.work_place} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.work_place}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Ailə tərkibi</Label>
                                <Input invalid={formErrors?.soldier_lang?.hobby?.length > 0} type="textarea" name="hobby" onChange={(e) => handleChangeLang(e)} value={soldierLang?.hobby} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.hobby}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Qısa məlumat</Label>
                                <Input type="textarea" name="description" onChange={(e) => handleChangeLang(e)} value={soldierLang?.description} />
                            </FormGroup>
                        </Row>
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Bioqrafiya</Label>
                                <Button color="primary" style={{ display: 'block', margin: 'auto' }} onClick={() => toggleFileModal()}>Qalereyadan seçmək</Button>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={bio ? bio : " "}
                                    onChange={handleBio}
                                    onInit={editor => {

                                    }}
                                    config={{
                                        ckfinder: {
                                            uploadUrl: URL + '/soldier/editor-image-upload',
                                        }
                                    }}
                                />

                                <Input type="hidden" invalid={true} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.biography}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={2}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Rütbə
                                </Label>
                                <Select
                                    value={soldier && rankOptionDict && rankOptionDict[soldier['rank_id']]}
                                    options={rankOptions}
                                    placeholder="Rütbə"
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="rank_id"
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.soldier?.rank)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.soldier?.rank}
                                </FormFeedback>
                            </FormGroup>

                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Mükəlləfiyyət
                                </Label>
                                <Select
                                    value={soldier && dutyTypeOptionsDict && dutyTypeOptionsDict[soldier['duty_type_id']]}
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="duty_type_id"
                                    options={dutyTypeOptions}
                                    placeholder="Mükəlləfiyyət"
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.soldier?.duty_type)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.soldier?.duty_type}
                                </FormFeedback>
                            </FormGroup>
                        </Row>

                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Əlillik dərəcəsi</Label>
                                <Input type="text" name="disability_degree" onChange={(e) => handleChangeLang(e)} value={soldierLang?.disability_degree} />
                            </FormGroup>
                        </Row>
                        <Row xs={3}>
                            {injuryPlaces.map((item, index) => (
                                <>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Label>
                                            Yaralandığı yer {index + 1}
                                        </Label>
                                        <Select
                                            name="place_id"
                                            value={injuryPlaces[index] && placeOptionDict && placeOptionDict[injuryPlaces[index]['place_id']]}
                                            onChange={(e) => handleChangeInjuryPlaces(e, 'select', index)}
                                            options={placeOptions}
                                            placeholder="Yaralandığı yer"
                                            invalid={formErrors?.places?.injury_place?.length > 0}
                                            styles={{
                                                control: styles => {
                                                    if (formErrors?.places?.injury_place)
                                                        return { ...styles, border: '1px solid #FB6442' }
                                                    return styles
                                                }
                                            }}
                                        />
                                        <Input type="text" invalid={true} style={{ display: 'none' }} />
                                        <FormFeedback>
                                            {formErrors?.places?.injury_place}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Label>
                                            Yaralandığı yer detallı
                                        </Label>
                                        <Input
                                            type="text"
                                            name="description"
                                            onChange={(e) => handleChangeInjuryPlaces(e, 'text', index)}
                                            value={injuryPlaces[index] && injuryPlaces[index]['description']} />
                                    </FormGroup>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Label>Yaralandığı tarix</Label>
                                        <DatePicker
                                            showYearDropdown
                                            placeholderText="dd.MM.yyyy"
                                            dateFormat="dd.MM.yyyy"
                                            selected={injuryPlaces[index] && injuryPlaces[index]['injury_date'] && new Date(injuryPlaces[index]['injury_date'])}
                                            onChange={(e) => handleChangeInjuryPlaces(e, 'date', index)}
                                        />
                                    </FormGroup>
                                </>
                            ))}
                        </Row>
                        <Button onClick={() => {
                            let all_places = [...injuryPlaces]
                            all_places.push({})
                            setInjuryPlaces(all_places)
                        }}>Yaralandığı yeri əlavə etmək</Button>

                        <Button onClick={() => {
                            let all_places = [...injuryPlaces]
                            all_places.pop()
                            setInjuryPlaces(all_places)
                        }}>Yaralandığı yerini silmek</Button>
                        <hr />
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Qoşun növü
                                </Label>
                                <Select
                                    value={soldier && troopTypeOptionsDict && troopTypeOptionsDict[soldier['troop_type_id']]}
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="troop_type_id"
                                    options={troopTypeOptions}
                                    placeholder="Qoşun növü"
                                    styles={{
                                        control: styles => {
                                            if (formErrors?.soldier?.troop_type)
                                                return { ...styles, border: '1px solid #FB6442' }
                                            return styles
                                        }
                                    }}
                                />
                                <Input type="text" invalid={true} style={{ display: 'none' }} />
                                <FormFeedback>
                                    {formErrors?.soldier?.troop_type}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Haqqında deyilənlər</Label>
                                <Input type='textarea' invalid={formErrors?.soldier_lang?.comments?.length > 0} name="comments" onChange={(e) => handleChangeLang(e)} value={soldierLang?.comments} />
                                <FormFeedback>
                                    {formErrors?.soldier_lang?.comments}
                                </FormFeedback>
                            </FormGroup>
                        </Row>
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>
                                    Təltiflər
                                </Label>
                                <Select
                                    isMulti
                                    value={soldier && soldier['medal_ids'] && medalOptionDict && soldier['medal_ids'].map(item => medalOptionDict[item])}
                                    onChange={(value, action) => handleSelect(value, action)}
                                    name="medal_ids"
                                    options={medalOptions}
                                    placeholder="Medallar"
                                />
                            </FormGroup>
                        </Row>
                        {battlePlaces.map((item, index) => (
                            <>
                                <Row xs={3}>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Label>
                                            Döyüşdüyü yer {index + 1}
                                        </Label>
                                        <Select
                                            name="place_id"
                                            value={battlePlaces[index] && placeOptionDict && placeOptionDict[battlePlaces[index]['place_id']]}
                                            onChange={(e, action) => handleChangeBattlePlaces(e, action, index)}
                                            options={placeOptions}
                                            invalid={formErrors?.places?.battle_places && formErrors?.places?.battle_places[index]?.place?.length > 0}
                                            placeholder="Döyüşdüyü yer"
                                            styles={{
                                                control: styles => {
                                                    if (formErrors?.places?.battle_places && formErrors?.places?.battle_places[index]?.place)
                                                        return { ...styles, border: '1px solid #FB6442' }
                                                    return styles
                                                }
                                            }}
                                        />
                                        <Input type="text" invalid={true} style={{ display: 'none' }} />
                                        <FormFeedback>
                                            {formErrors?.places?.battle_places && formErrors?.places?.battle_places[index]?.place}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup style={{ padding: 10 }}>
                                        <Label>
                                            Döyüşdüyü yer detallı
                                        </Label>
                                        <Input
                                            type="text"
                                            name="description"
                                            onChange={(e) => handleChangeBattlePlaces(e, null, index)}
                                            value={battlePlaces[index] && battlePlaces[index]['description']} />
                                    </FormGroup>
                                    {item['id'] &&
                                        <FormGroup style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <Button color="danger" style={{ width: '60%' }} onClick={() => deleteBattlePlace(item['soldier_place_id'])}>Sil</Button>
                                        </FormGroup>
                                    }
                                </Row>
                                <hr></hr>
                            </>
                        ))}
                        <Button onClick={() => {
                            let all_places = [...battlePlaces]
                            all_places.push({})
                            setBattlePlaces(all_places)
                        }}>Döyüş yeri əlavə etmək</Button>

                        <Button onClick={() => {
                            let all_places = [...battlePlaces]
                            all_places.pop()
                            setBattlePlaces(all_places)
                        }}>Döyüş yerini silmek</Button>
                        <hr></hr>
                        <h2>Qalereya</h2>
                        {!toCreate &&
                            <>
                                {dbImages.slice(1).map(item => {
                                    return <DeleteImage item={item} deleteImage={deleteImage} />
                                })}
                            </>
                        }
                        <Row xs={1}>
                            <FormGroup style={{ padding: 10 }}>
                                <Label>Digər fayllar</Label>
                                <CustomInput
                                    id="image"
                                    multiple
                                    label="Fayllari seçmək"
                                    onChange={(e) => handleChangeImage(e)}
                                    name="other"
                                    type="file"
                                />
                            </FormGroup>
                        </Row>
                        <hr />
                        <h2>Videolar</h2>
                        {videos.map((item, index) => (
                            <Row xs={item['id'] ? 4 : 3}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Video linki {index + 1}</Label>
                                    <Input type="text" name="video_link" value={videos[index] && videos[index].video_link} onChange={(e) => handleChangeVideo(e, null, index)} />
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>
                                        Sıra Nömrəsi
                                    </Label>
                                    <Input type="number" name="rank" value={videos[index] && videos[index].rank} onChange={(e) => handleChangeVideo(e, null, index)} />
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }} >
                                    <Label>
                                        Növü
                                    </Label>
                                    <Select
                                        name="link_type"
                                        value={videos[index] && { value: videos[index].link_type, label: videos[index].link_type }}
                                        onChange={(e, action) => handleChangeVideo(e, action, index)}
                                        options={videoTypeOptions}
                                        placeholder="Video Növü"
                                    />
                                </FormGroup>
                                {item['id'] &&
                                    <FormGroup style={{ marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button color="danger" style={{ width: '60%' }} onClick={() => deleteVideo(item['id'])}>Sil</Button>
                                    </FormGroup>
                                }
                            </Row>
                        ))}
                        <Button onClick={() => {
                            let all_videos = [...videos]
                            all_videos.push({})
                            setVideos(all_videos)
                        }}>Video əlavə etmək</Button>
                        <Button onClick={() => {
                            let all_videos = [...videos]
                            all_videos.pop()
                            setVideos(all_videos)
                        }}>Videonu silmək</Button>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleCreateUpdateModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => submitForm()}>
                            {toCreate ? 'Əlavə et' : 'Düzəliş et'}
                        </Button>
                    </div>
                </Modal>

                {translationModal &&
                    <Modal
                        className="modal-dialog-centered"
                        isOpen={translationModal}
                        toggle={() => toggleTranslationModal()}
                        backdrop={'static'}
                        size="lg"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Tərcümələr
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => toggleTranslationModal()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Yeni tərcümə əlavə etmək və ya düzəliş etmək
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th>Dil</th>
                                        <th>#id</th>
                                        <th>Ad</th>
                                        <th>Soyad</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {languages ? languages.map((item, index) =>
                                    (
                                        <tr>
                                            <td>{item['short_name']}</td>
                                            <td>{translations[item['id']] && translations[item['id']]['id']}</td>
                                            <td>{translations[item['id']] && translations[item['id']]['name']}</td>
                                            <td>{translations[item['id']] && translations[item['id']]['surname']}</td>
                                            {translations[item['id']] ?
                                                (<td style={{ textAlign: 'center' }}>
                                                    <Button
                                                        className="btn-icon btn-3"
                                                        color="success"
                                                        type="button"
                                                        onClick={() => {
                                                            setLanguageShort(item['short_name'])
                                                            getSoldierToUpdate(item['short_name'])
                                                            setToCreate(false)
                                                            toggleTranslationModal()
                                                            toggleCreateUpdateModal()
                                                        }}
                                                    >Düzəliş etmək
                                                    </Button>
                                                    {/* <Button className="btn-icon btn-3" color="danger" type="button">Silmək</Button> */}
                                                </td>) :
                                                (<td style={{ textAlign: 'center' }}>
                                                    <Button
                                                        className="btn-icon btn-3"
                                                        onClick={() => {
                                                            toggleTranslationModal()
                                                            toggleAddLanguageModal(item['id'])
                                                            setLanguageShort(item['short_name'])
                                                        }}
                                                        color="info" type="button">Əlavə etmək</Button>
                                                </td>)
                                            }


                                        </tr>
                                    )
                                    ) :
                                        <h1>Loading</h1>}
                                </tbody>
                            </Table>

                        </div>
                        <div className="modal-footer">
                            <Button
                                color="secondary"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => toggleTranslationModal()}
                            >
                                Bağla
                            </Button>
                        </div>
                    </Modal>
                }

                {addLanguageModal &&
                    <Modal
                        className="modal-dialog-centered"
                        size="xl"
                        isOpen={addLanguageModal}
                        toggle={() => toggleAddLanguageModal()}
                        backdrop={'static'}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLabel">
                                Qazinin məlumatlarına tərcümə əlavə etmək ({languageShort})
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => toggleAddLanguageModal()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Row xs={3}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Adı</Label>
                                    <Input invalid={formErrors?.soldier_lang?.name?.length > 0} type="text" name="name" onChange={(e) => handleChangeLang(e)} value={soldierLang?.name} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.name}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Soyadı</Label>
                                    <Input invalid={formErrors?.soldier_lang?.surname?.length > 0} type="text" name="surname" onChange={(e) => handleChangeLang(e)} value={soldierLang?.surname} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.surname}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Ata adı</Label>
                                    <Input invalid={formErrors?.soldier_lang?.father_name?.length > 0} type="text" name="father_name" onChange={(e) => handleChangeLang(e)} value={soldierLang?.father_name} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.father_name}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                            <Row xs={2}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>
                                        Doğum yeri
                                    </Label>
                                    <Select
                                        name="place_id"
                                        isDisabled
                                        value={places['birth_place'] && placeOptionDict[places['birth_place']['place_id']]}
                                        onChange={(value, action) => handleChangePlace('birth_place', value, action)}
                                        options={placeOptions}
                                        placeholder="Doğum yeri"
                                        styles={{
                                            control: styles => {
                                                if (formErrors?.places?.birth_place)
                                                    return { ...styles, border: '1px solid #FB6442' }
                                                return styles
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>
                                        Doğum yeri detallı
                                    </Label>
                                    <Input type="text"
                                        name="description"
                                        onChange={(e) => handleChangePlace('birth_place', e)}
                                        value={places['birth_place'] && places['birth_place']['description']} />
                                </FormGroup>
                            </Row>
                            <Row xs={3}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Oxuduğu məktəb</Label>
                                    <Input invalid={formErrors?.soldier_lang?.school?.length > 0} type="text" name="school" onChange={(e) => handleChangeLang(e)} value={soldierLang?.school} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.school}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Ali təhsil müəssisəsi</Label>
                                    <Input invalid={formErrors?.soldier_lang?.university?.length > 0} type="text" name="university" onChange={(e) => handleChangeLang(e)} value={soldierLang?.university} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.university}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>İş yeri</Label>
                                    <Input invalid={formErrors?.soldier_lang?.work_place?.length > 0} type="text" name="work_place" onChange={(e) => handleChangeLang(e)} value={soldierLang?.work_place} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.work_place}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                            <Row xs={1}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Hobbilər</Label>
                                    <Input invalid={formErrors?.soldier_lang?.hobby?.length > 0} type="text" name="hobby" onChange={(e) => handleChangeLang(e)} value={soldierLang?.hobby} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.hobby}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                            <Row xs={1}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Qısa məlumat</Label>
                                    <Input type="textarea" name="description" onChange={(e) => handleChangeLang(e)} value={soldierLang?.description} />
                                </FormGroup>
                            </Row>
                            <Row xs={1}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Bioqrafiya</Label>
                                    <Button color="primary" style={{ display: 'block', margin: 'auto' }} onClick={() => toggleFileModal()}>Qalereyadan seçmək</Button>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={bio ? bio : " "}
                                        onChange={handleBio}
                                        onInit={editor => {

                                        }}
                                        config={{
                                            ckfinder: {
                                                uploadUrl: URL + '/soldier/editor-image-upload',
                                            }
                                        }}
                                    />
                                    <Input type="hidden" invalid={true} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.biography}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                            <Row xs={1}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Haqqında deyilənlər</Label>
                                    <Input type='textarea' invalid={formErrors?.soldier_lang?.comments?.length > 0} name="comments" onChange={(e) => handleChangeLang(e)} value={soldierLang?.comments} />
                                    <FormFeedback>
                                        {formErrors?.soldier_lang?.comments}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                            <Row xs={1}>
                                <FormGroup style={{ padding: 10 }}>
                                    <Label>Əlillik dərəcəsi</Label>
                                    <Input type="text" name="disability_degree" onChange={(e) => handleChangeLang(e)} value={soldierLang?.disability_degree} />
                                </FormGroup>
                            </Row>
                            <Row xs={2}>
                                {injuryPlaces.map((item, index) => (
                                    <>
                                        <FormGroup style={{ padding: 10 }}>
                                            <Label>
                                                Yaralandığı yer {index + 1}
                                            </Label>
                                            <Select
                                                name="place_id"
                                                value={injuryPlaces[index] && placeOptionDict && placeOptionDict[injuryPlaces[index]['place_id']]}
                                                options={placeOptions}
                                                isDisabled
                                                placeholder="Yaralandığı yer"
                                            />
                                            <Input type="text" invalid={true} style={{ display: 'none' }} />
                                            <FormFeedback>
                                                {formErrors?.places?.injury_place}
                                            </FormFeedback>
                                        </FormGroup>
                                        <FormGroup style={{ padding: 10 }}>
                                            <Label>
                                                Yaralandığı yer detallı
                                            </Label>
                                            <Input
                                                type="text"
                                                name="description"
                                                onChange={(e) => handleChangeInjuryPlaces(e, 'text', index)}
                                                value={injuryPlaces[index] && injuryPlaces[index]['description']} />
                                        </FormGroup>
                                    </>
                                ))}
                                {battlePlaces.map((item, index) => (
                                    <>
                                        <FormGroup style={{ padding: 10 }}>
                                            <Label>
                                                Döyüşdüyü yer {index + 1}
                                            </Label>
                                            <Select
                                                name="place_id"
                                                isDisabled
                                                value={placeOptionDict[item['place_id']]}
                                                onChange={(e, action) => handleChangeBattlePlaces(e, action, index)}
                                                options={placeOptions}
                                                placeholder="Döyüşdüyü yer"
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ padding: 10 }}>
                                            <Label>
                                                Döyüşdüyü yer detallı
                                            </Label>
                                            <Input
                                                type="text"
                                                name="description"
                                                onChange={(e) => handleChangeBattlePlaces(e, null, index)}
                                                value={battlePlaces[index] && battlePlaces[index]['description']} />
                                        </FormGroup>
                                    </>
                                ))}
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <Button
                                color="secondary"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => toggleAddLanguageModal()}
                            >
                                Bağla
                            </Button>
                            <Button color="primary" type="button" onClick={() => submitAddLanguage()}>
                                Əlavə et
                            </Button>
                        </div>
                    </Modal>
                }

                {fileModal &&
                    <Modal
                        toggle={() => toggleFileModal()}
                        isOpen={fileModal}
                        backdrop={'static'}
                        scrollable={true}
                        size="xl"
                    >
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalLabel">
                                Şəkil qalereyası
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => toggleFileModal()}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <Row>
                                <Col xs="6" sm="4" md="4">
                                    <Nav tabs vertical pills>
                                        {gallery && Object.keys(gallery).map((item, index) => (
                                            <div key={index} style={{ marginTop: "10px" }}>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === index + 1 })}
                                                        onClick={() => {
                                                            setActiveTab(index + 1);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {String(item).toUpperCase()}
                                                    </NavLink>
                                                </NavItem>
                                            </div>
                                        ))}
                                    </Nav>
                                </Col>
                                <Col xs="6" sm="6" md="8">
                                    <TabContent activeTab={activeTab}>
                                        {gallery && Object.keys(gallery).map((item, index) => (
                                            <TabPane tabId={index + 1}>
                                                <Row xs={4}>
                                                    {gallery[item].map(image => (
                                                        <Col>
                                                            <img
                                                                style={{ margin: 10, cursor: 'pointer' }}
                                                                width={150} height={150} src={MEDIA_URL + image}
                                                                onClick={() => {
                                                                    let content = bio
                                                                    content += `<img  src='${MEDIA_URL + image}' />`
                                                                    setBio(content)
                                                                    toggleFileModal()

                                                                }}
                                                            />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <Button onClick={() => toggleFileModal()}>
                                Bağla
                            </Button>
                        </div>
                    </Modal>
                }


                <DeleteModal
                    isOpen={deleteModal}
                    toggleDeleteModal={toggleDeleteModal}
                    title={"Xəbəri silmək"}
                    bodyText={"Bu xəbəri silmək istədiyinizə əminsiniz?"}
                    itemID={itemID}
                    deleteAction={deleteSoldier} />
            </Container>
        </>
    );
};

export default Tables;
