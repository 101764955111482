import { Table, Button } from "reactstrap"

const GeneralTable = ({ columns, handleSort, getIcon, toggleUpdateModal, toggleDeleteModal, data, condition_field, notEdit }) => {
    return (
        <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
                <tr>
                    {columns.map((item, index) => (
                        <th key={index} scope="col" onClick={item.notSortable ? undefined : () => handleSort(item.field)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                            { item.title} {getIcon(item.field)}
                        </th>
                    ))
                    }
                    <th scope="col" style={{ textAlign: 'center'}}>Əməliyyatlar</th>
                </tr>
            </thead>
            <tbody>
                {data ? data['items']?.map((item, index) =>
                (
                    <tr key={index}>
                        {columns.map(column => (
                            <td style={{ textAlign: 'center', backgroundColor: item[condition_field] && '#EFEFEF' }}>
                                {column.count &&  String(item[column.field]).length > column.count ? item[column.field].substring(0, column.count) + '...' : item[column.field]}
                            </td>
                        ))}
                        <td style={{ textAlign: 'center', backgroundColor: item[condition_field] && '#EFEFEF' }}>
                            {!notEdit && <Button className="btn-icon btn-3" color="success" onClick={() => toggleUpdateModal(item['id'])} type="button">Düzəliş etmək</Button>}
                            {!item[condition_field] && (<Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(item['id'])} type="button">Silmək</Button>)}
                        </td>
                    </tr>
                )
                ) :
                    null}
            </tbody>
        </Table>
    )
}

export default GeneralTable