import { useState } from "react"
import { Button, Row } from "reactstrap"
import { MEDIA_URL } from "../../config"
import DeleteModal from "./DeleteModal"

const DeleteImage = ({ item, deleteImage }) => {
    const [deleteModal, setDeleteModal] = useState(false)

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    return (
        <>
            <Row style={{ marginLeft: 10, marginBottom: 15 }}>
                {item['file_type'] == 'image' ?
                    <img width={300} src={MEDIA_URL + item['image']} alt="Not found" />
                    :
                    <video width="300" controls>
                        <source src={MEDIA_URL + item['image']} alt="Not found"/>
                    </video>
                }
                <Button color="danger" style={{ height: 60, margin: 20 }} onClick={toggleDeleteModal}>Silmək</Button>
            </Row>

            <DeleteModal
                isOpen={deleteModal}
                toggleDeleteModal={toggleDeleteModal}
                title={"Şəkli silmək"}
                bodyText={"Bu şəkli silmək istədiyinizə əminsiniz?"}
                itemID={item['id']}
                deleteAction={deleteImage} />
        </>

    )
}

export default DeleteImage