import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Container,
  Row,
  FormGroup,
  FormFeedback,
  Input,
  Col,
  Form,
  TabPane,
  TabContent,
  NavLink,
  NavItem,
  Nav,
  Modal,
  Label
} from "reactstrap";
import { URL } from '../../config'
import classnames from 'classnames'
// core components
import GeneralTable from '../components/GeneralTable'
import DeleteModal from '../components/DeleteModal'
import GeneralPagination from "../components/GeneralPagination";
import { AuthContext } from "../../context";


const Menu = (props) => {
  const [allRecords, setallRecords] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [itemID, setItemID] = useState()
  const [toCreate, setToCreate] = useState(true)
  const [languages, setLanguages] = useState([])
  const [oneRecord, setoneRecord] = useState({ menu: {}, menu_lang: [] })
  const [formErrors, setFormErrors] = useState({ menu: {}, menu_lang: [] })
  const [query, setQuery] = useState()
  const [sort, setSort] = useState({ field: "", order: "" })

  const token = JSON.parse(localStorage.getItem("token"))
  const authContext = useContext(AuthContext)

  const handleChange = (e) => {
    let updatedData = { ...oneRecord }
    updatedData.menu = { ...updatedData.menu, [e.target.name]: e.target.value }
    setoneRecord(updatedData)
  }

  const handleChangeLang = (e, index, lang_id) => {
    let updatedData = { ...oneRecord }
    let updatedRecord = updatedData['menu_lang']
    if (updatedRecord != undefined && updatedRecord[index]) {
      updatedData.menu_lang = [...updatedRecord.slice(0, index), { ...updatedRecord[index], name: e.target.value }, ...updatedRecord.slice(index + 1)]
    }
    else {
      updatedData.menu_lang = [...(updatedRecord || []), { name: e.target.value, lang_id: lang_id }]
    }
    setoneRecord(updatedData)
  };

  const validate = () => {
    let obj = oneRecord.menu
    let obj_lang = oneRecord.menu_lang
    let errors = { ...formErrors }
    let generalValid = true
    if (obj_lang && obj_lang[0]) {
      if (obj_lang[0].name === undefined || obj_lang[0].name == '') {
        errors.menu_lang = [{ name: 'Bu xananı doldurmaq lazımdır!' }]
        generalValid = false
      }
    }
    else {
      errors.menu_lang = [{ name: 'Bu xananı doldurmaq lazımdır!' }]
      generalValid = false
    }

    if (obj) {
      if (obj.link === undefined || obj.link === '') {
        errors.menu = { link: 'Bu xananı doldurmaq lazımdır!' }
        generalValid = false
      }
    }
    else {
      errors.menu = { link: 'Bu xananı doldurmaq lazımdır!' }
      generalValid = false
    }
    setFormErrors(errors)
    return generalValid
  }


  const validateUpdate = () => {
    let obj = oneRecord.menu
    let obj_lang = oneRecord.menu_lang
    let errors = { ...formErrors }
    let generalValid = true
    if (obj_lang) {
      for (let i = 0; i < obj_lang.length; i++) {
        let errorMessage = ""
        let valid = true
        if (obj_lang[i].name === undefined || obj_lang[i].name == '') {
          errorMessage = 'Bu xananı doldurmaq lazımdır!'
          valid = false
          generalValid = false
        }
        errors.menu_lang[i] = { name: errorMessage, valid: valid }
      }
    }
    else {
      for (let i = 0; i < obj_lang.length; i++) {
        errors.menu_lang[i] = { name: 'Bu xananı doldurmaq lazımdır!', valid: false }
        errors.valid = false
        generalValid = false
      }
    }
    if (obj) {
      if (obj.link === undefined || obj.link == '') {
        errors.menu = { link: 'Bu xananı doldurmaq lazımdır!' }
        generalValid = false
      }
    } else {
      errors.menu = { link: 'Bu xananı doldurmaq lazımdır!' }
      generalValid = false
    }
    setFormErrors(errors)
    return generalValid
  }


  const handleQuery = (e) => {
    let value = e.target.value
    if (value.length >= 3) {
      fetchallRecords(1, null, value)
    }
    if (value.length < query?.length) {
      fetchallRecords(1)
    }
    setQuery(value)
  }

  const toggle = (index) => {
    setActiveTab(index)
  };

  const toggleCreateUpdateModal = () => {
    setModalOpen(!modalOpen)
    setActiveTab(1)
    setoneRecord([])
    setFormErrors({ menu_lang: { name: '' }, valid: true })
  }

  const toggleDeleteModal = (id) => {
    setItemID(id)
    setDeleteModal(!deleteModal)
  }



  const fetchallRecords = (page = 1, orderObj = null, query = null) => {
    let url = page > 0 ? `${URL}/menuaz/all?page=${page}` : `${URL}/menuaz/all?page=${1}`
    if (orderObj?.field) {
      url += `&field=${orderObj.field}&order=${orderObj.order}`
    }
    if (query) {
      url += `&q=${query}`
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(result => {
      if (!result.ok) throw result;
      return result.json();
    }).then(result => {
      setallRecords(result)
    }).catch(error => {
      if (error.status == 401) {
        authContext.logout()
      }
    })
  }

  const getIcon = (field) => {
    if (field === sort.field) {
      switch (sort.order) {
        case "asc":
          return (<i className="fas fa-angle-up" />)
        case "desc":
          return (<i className="fas fa-angle-down" />)
      }
    }
  }

  const changePage = (page) => {
    fetchallRecords(page, sort)
  }

  const handleSort = (field) => {
    let order;
    if (sort.order === "asc" && sort.field === field)
      order = "desc";
    else {
      order = "asc"
    }
    let orderObj = { field: field, order: order }
    setSort(orderObj)
    fetchallRecords(1, orderObj, query)
  }

  const deleteMenu = (id) => {
    fetch(`${URL}/menu/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(result => {
      if (!result.ok) throw result;
      return result.json();
    }).then(result => {
      fetchallRecords(allRecords['current_page'], sort, query)
      toggleDeleteModal()
    }).catch(error => {
      if (error.status == 401) {
        authContext.logout()
      }
    })
  }

  const submitForm = () => {
    let validationCheck = toCreate ? validate : validateUpdate
    if (validationCheck()) {
      let url = toCreate ? `${URL}/menu/` : `${URL}/menu/update/${itemID}`
      let method = toCreate ? 'POST' : 'PUT'
      fetch(url, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(oneRecord)
      }).then(result => {
        if (!result.ok) throw result;
        return result.json();
      })
        .then(result => {
          fetchallRecords(allRecords['current_page'], sort)
          toggleCreateUpdateModal()
        }).catch(error => {
          if (error.status == 401) {
            authContext.logout()
          }
        })
    }
  }

  const createModal = () => {
    setToCreate(true)
    toggleCreateUpdateModal()
  }


  const getMenuData = (id) => {
    fetch(`${URL}/menu/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(result => {
      if (!result.ok) throw result;
      return result.json();
    }).then(result => {
      let menu_lang = result['menu_lang']
      delete result['menu_lang']
      setoneRecord({ menu: result, menu_lang: menu_lang })
    }).catch(error => {
      if (error.status == 401) {
        authContext.logout()
      }
    })
  }


  const updateModal = (id) => {
    getMenuData(id)
    setItemID(id)
    setToCreate(false)
    toggleCreateUpdateModal()
  }


  const getLanguages = () => {
    fetch(`${URL}/language/all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(result => {
      if (!result.ok) throw result;
      return result.json();
    }).then(result => {
      setLanguages(result)
    }).catch(error => {
      if (error.status == 401) {
        authContext.logout()
      }
    })
  }


  useEffect(() => {
    getLanguages()
    fetchallRecords()
  }, [])

  const onSubmit = (token) => {
    console.log(token)
  }

  return (
    <>
      {/* Page content */}
      <Container fluid>
        {/* Table */}
        <Row>
          <div className="col mt-5  offset-md-2">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between">
                <h3 className="mb-0">Menu</h3>
                <FormGroup>
                  <Input
                    id="search"
                    onChange={(e) => handleQuery(e)}
                    value={query}
                    placeholder="Axtarış..."
                    type="text"
                  />
                </FormGroup>
                <Button className="btn-icon btn-3" onClick={() => createModal()} color="primary" type="button">Menu əlavə et</Button>
              </CardHeader>
              <GeneralTable
                columns={[
                  { title: 'ID', field: 'id' },
                  { title: 'Adı', field: 'name' }
                ]}
                handleSort={handleSort}
                getIcon={getIcon}
                toggleUpdateModal={updateModal}
                toggleDeleteModal={toggleDeleteModal}
                data={allRecords}
              />
              <CardFooter className="py-4">
                <GeneralPagination
                  data={allRecords}
                  changePage={changePage}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>

        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={() => toggleCreateUpdateModal()}
          backdrop={'static'}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Menu yaratmaq
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleCreateUpdateModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col xs="6" sm="4" md="4">
                <Nav tabs vertical pills>
                  {languages && languages.map((item, index) => (
                    <div key={index} style={{ marginTop: "10px" }}>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === index + 1 })}
                          onClick={() => {
                            toggle(index + 1);
                          }}
                        >
                          {String(item['short_name']).toUpperCase()}
                          {formErrors.menu_lang[index]?.name.length > 0 &&
                            <i style={{ color: 'red' }} className="ni ni-fat-remove" />
                          }
                        </NavLink>
                      </NavItem>
                    </div>
                  ))}
                </Nav>
              </Col>
              <Col xs="6" sm="6" md="6">
                <TabContent activeTab={activeTab}>
                  {languages && languages.map((language_data, index) => (
                    <TabPane tabId={index + 1}>
                      <Form>
                        <Row>
                          <Col>
                            <FormGroup>
                              <h5>Cari dil: {String(language_data['short_name']).toUpperCase()}</h5>
                              <Label>Ad</Label>
                              <Input
                                invalid={formErrors.menu_lang[index]?.name.length > 0}
                                id="exampleFormControlInput1"
                                name='name'
                                onChange={(e) => handleChangeLang(e, index, language_data['id'])}
                                value={oneRecord['menu_lang'] && oneRecord['menu_lang'][index]?.name}
                                placeholder="Menu adi..."
                                type="text"
                              />

                              <FormFeedback>
                                {formErrors?.menu_lang[index]?.name}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  ))}
                </TabContent>
                <FormGroup>
                  <Label>Link</Label>
                  <Input
                    invalid={formErrors?.menu?.link?.length > 0}
                    id="exampleFormControlInput1"
                    name='link'
                    onChange={(e) => handleChange(e)}
                    value={oneRecord?.menu && oneRecord.menu?.link}
                    placeholder="Menu linki..."
                    type="text"
                  />

                  <FormFeedback>
                    {formErrors?.menu?.link}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleCreateUpdateModal()}
            >
              Bağla
            </Button>
            <Button color="primary" type="button" onClick={() => submitForm()}>
              {toCreate ? 'Əlavə et' : 'Düzəliş et'}
            </Button>
          </div>
        </Modal>



        <DeleteModal
          isOpen={deleteModal}
          toggleDeleteModal={toggleDeleteModal}
          title={"Menu silmək"}
          bodyText={"Bu menunu silmək istədiyinizə əminsiniz?"}
          itemID={itemID}
          deleteAction={deleteMenu} />
      </Container>
    </>
  );
};

export default Menu;