import { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    Button,
    Table,
    Container,
    Modal,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Form
} from "reactstrap";
// core components
import { AuthContext } from "../../context";
import { MEDIA_URL, URL } from '../../config'

const Tables = () => {
    const [contactData, setcontactData] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showDataModal, setShowDataModal] = useState(false)
    const [itemID, setItemID] = useState()
    const [data, setData] = useState({})

    const token = JSON.parse(localStorage.getItem("token"))
    const authContext = useContext(AuthContext)


    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }

    const toggleDeleteModal = (id) => {
        setItemID(id)
        setDeleteModal(!deleteModal)
    }

    const fetchcontactData = () => {
        fetch(`${URL}/contact/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setcontactData(result)
        }).catch(error => {
            if (error.status === 401) {
                authContext.logout()
            }
        })
    }

    const deleteContactData = (id) => {
        fetch(`${URL}/contact/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            fetchcontactData()
            toggleDeleteModal()
        }).catch(error => {
            if (error.status === 401) {
                authContext.logout()
            }
        })
    }

    const updateContact = () => {
        let url = `${URL}/contact/update/${itemID}`
        let method = 'PUT'
        fetch(url, {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ is_active: !(data.is_active) })
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setData([])
            fetchcontactData()
            toggleModal()
        }).catch(error => {
            if (error.status === 401) {
                authContext.logout()
            }
        })
    }

    const updateModal = (id) => {
        fetch(`${URL}/contact/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setData(result)
        }).catch(error => {
            if (error.status === 401) {
                authContext.logout()
            }
        })
        setItemID(id)
        toggleModal()
    }

    const showData = (id) => {
        fetch(`${URL}/contact/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(result => {
            if (!result.ok) throw result;
            return result.json();
        }).then(result => {
            setData(result)
        }).catch(error => {
            if (error.status === 401) {
                authContext.logout()
            }
        })
        setShowDataModal(true)
    }

    useEffect(() => {
        fetchcontactData()
    }, [])


    return (
        <>
            {/* Page content */}
            <Container fluid>
                {/* Table */}
                <Row>
                    <div className="col mt-5 offset-md-2">
                        <Card className="shadow">
                            <CardHeader className="border-0 d-flex justify-content-between">
                                <h3 className="mb-0">Bizimlə əlaqə</h3>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ textAlign: 'center' }}>#id</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Email</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Mövzu</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Status</th>
                                        <th scope="col" style={{ textAlign: 'center' }}>Əməliyyatlar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactData && contactData.map((item, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{item['email']}</td>
                                            <td style={{ textAlign: 'center' }}>{String(item['subject']).substring(0, 30)} </td>
                                            <td style={{ textAlign: 'center' }}>{item['is_active'] ? <i style={{ color: 'green' }} className="ni ni-check-bold"></i> : <i style={{ color: 'red' }} className="ni ni-fat-delete"></i>}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Button className="btn-icon btn-3" color="info" onClick={() => showData(item['id'])} type="button">Baxmaq</Button>
                                                <Button className="btn-icon btn-3" color="success" onClick={() => updateModal(item['id'])} type="button">{item['is_active'] ? 'Deaktiv' : 'Aktiv'} etmək</Button>
                                                <Button className="btn-icon btn-3" color="danger" onClick={() => toggleDeleteModal(item['id'])} type="button">Silmək</Button>
                                            </td>
                                        </tr>
                                    )
                                    )}

                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
                {/* Modal */}


                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={() => toggleModal()}
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Bu əlaqəni {data?.is_active ? 'deaktiv' : 'aktiv'} etmək istəyirsiz?
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => updateContact()}>
                            {data?.is_active ? 'Deaktiv' : 'Aktiv'} et
                        </Button>
                    </div>
                </Modal>



                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteModal}
                    toggle={() => toggleDeleteModal()}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Silmək
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Silmək istədiyinizə əminsiniz?
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Bağla
                        </Button>
                        <Button color="primary" type="button" onClick={() => deleteContactData(itemID)}>
                            Silmək
                        </Button>
                    </div>
                </Modal>


                <Modal
                    className="modal-dialog-centered"
                    isOpen={showDataModal}
                    toggle={() => setShowDataModal(!showDataModal)}
                    size="lg"
                    backdrop={'static'}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Bizimlə əlaqə
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowDataModal(!showDataModal)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <Row>
                                <Col>
                                    {data?.name &&
                                        <FormGroup>
                                            <Label for="name">
                                                Adı
                                            </Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                disabled
                                                type="text"
                                                value={data?.name}
                                            />
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <Label for="email">
                                            Email
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            disabled
                                            type="text"
                                            value={data?.email}
                                        />
                                    </FormGroup>
                                    {data?.subject &&
                                        <FormGroup>
                                            <Label for="subject">
                                                Mövzu
                                            </Label>
                                            <Input
                                                id="subject"
                                                name="subject"
                                                disabled
                                                type="text"
                                                value={data?.subject}
                                            />
                                        </FormGroup>
                                    }
                                    {data?.message &&
                                        <FormGroup>
                                            <Label for="message">
                                                Mesaj
                                            </Label>
                                            <Input
                                                id="message"
                                                name="message"
                                                disabled
                                                type="textarea"
                                                value={data?.message}
                                            />
                                        </FormGroup>
                                    }
                                    {data?.soldier_id &&
                                        <FormGroup>
                                            <Label for="soldier">
                                                Əsgər
                                            </Label>
                                            <Input
                                                id="soldier"
                                                name="soldier"
                                                disabled
                                                type="text"
                                                value={data?.soldier_name}
                                            />
                                        </FormGroup>
                                    }
                                    {data?.file &&
                                        <FormGroup>
                                            <Label for="file">
                                                Fayl
                                            </Label>
                                            <Input
                                                id="file"
                                                name="file"
                                                disabled
                                                type="text"
                                                value={data?.file}
                                            />
                                            <br></br>
                                            <Button type="reset" color="primary">
                                                <a
                                                    href={`${MEDIA_URL}${data?.file}`}
                                                    style={{ color: 'white' }}
                                                    target='_blank'
                                                    download
                                                >
                                                    Yüklə
                                                </a>
                                            </Button>

                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                        </Form>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowDataModal(!showDataModal)}
                        >
                            Bağla
                        </Button>
                    </div>
                </Modal>


            </Container>
        </>
    );
};

export default Tables;
